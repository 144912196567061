/* Blog General  ****************************************************/
article.post-wrap{
	
	margin-bottom: 70px;

	&.sticky{
		background: #f9f9f9;
		padding: 30px;
		border-top: 5px solid;
		border-color: var(--primary);

		.post-excerpt{ 
			text-align: justify;
		}
		
	}

	.post-title{
		margin: 0px 0px 20px 0px;
		padding: 0;
		line-height: 1.3em;
		font-weight: bold;
		
		color: var(--heading);
		-webkit-hyphens: unset;
	    -moz-hyphens: unset;
	    -ms-hyphens: unset;
	    hyphens: unset;
	    word-break: break-word;
		&:hover{
			color: var(--primary);
		}
	}

	.post-media{
		margin-bottom: 30px;
		
		img{
			width: 100%;
			object-fit: cover;
		}
		
	}

	
	ul.post-meta{
		list-style-type: none;
		margin: 0 0px 25px 0px;
		padding: 0;
		display: block;
		li{
			display: inline-block;
			margin: 0 16px 0px 0;
			color: var(--text);
			opacity: 0.8;
			line-height: 1.2;

			&:hover{
				opacity: 1;
			}
			i{
				font-size: 0.9em;
				color: var(--primary);
				padding-right: 3px;
			}
			a{
				color: var(--text);
				&:hover{
					color: var(--primary);
				}
			}
		}	
	}
	

	.post-excerpt{
		& > p{
			margin-top: 0px;
			margin-bottom: 25px;
		}
	}
		
	a.readmore{
		clear: both;
		background-color: var(--primary);
		border-color: var(--primary);
		border: 1px solid;
		color: #fff;
	    font-size: 1em;
	    text-transform: none;
	    font-weight: 600;
	    box-shadow: none;
	    display: inline-block;
	    @include transaction( all, 0.2s, linear );
	    &:hover{
			color: var(--primary);
			border-color: var(--primary);
			background-color: #fff;
		}	
	}

}


/* Blog Default ****************************************************/
.blog_default{
	article.post-wrap{
		.post-media{
			img{
				max-height: 530px;
				width: 100%;
				object-fit: cover;
			}
		}
	}
	.post-title{
		font-weight: normal;
	}

	.post-excerpt{
		font-size: 1em;
	}

	.date{
		font-size: 1em;
	}

}

.layout_1c{
	.blog_default{
		max-width: 1000px;
		margin: 0 auto;
	}
}


/*Blog Grid ***************************************************/
.blog_grid{
	
	display: grid;
    gap: 30px;
    row-gap: 60px;
    grid-template-columns: repeat(3, 1fr);

    @media( max-width: $desktop - 1 ){
		grid-template-columns: repeat(2, 1fr);    	
    }
    @media( max-width: 767px ){
    	grid-template-columns: repeat(1, 1fr);
    }

    article.post-wrap{
    	margin-bottom: 0;
    	.post-media{
			margin-bottom: 15px;
    		img{
    			height: 280px;
				object-fit: cover;
				@media(max-width: 767px){
					height: auto;
				}
    		}
		}

		.post-title{
			font-size: 22px;
		}

		.post-excerpt{
			font-size: 1em;
		}
		
		ul.post-meta{
			margin-bottom: 15px;
			.date{
				font-size: 1em;
			}		
		}
		
    }

    
}

.layout_2l, .layout_2r{
	.blog_grid{
		grid-template-columns: repeat(2, 1fr);
		 @media( max-width: 767px ){
	    	grid-template-columns: repeat(1, 1fr);
	    }
	}
}


/* Blog masonry ***************************************************/
.blog_masonry{
	margin-left: -15px;
	margin-right: -15px;
	article{
		width: 33.3%;

		@media(max-width: 1200px){
			width: 50%;
		}
		@media( max-width: 767px ){
			width: 100%;
		}
	}
	.content{
		margin: 0px 15px;
		position: relative;
		min-height: 100px;
		
		.post-media{
			margin-bottom: 15px;
		}

		.post-title{
			font-size: 22px;
		}

		.post-excerpt{
			font-size: 1em;
		}
		
		ul.post-meta{
			margin-bottom: 15px;
			.date{
				font-size: 1em;
			}		
		}

		a.readmore{
			clear: both;
			background-color: transparent;
			border-color: var(--primary);
			border: 1px solid;
			color: var(--primary);
		    
		    @include transaction( all, 0.2s, linear );
		    &:hover{
				color: #fff;
				border-color: var(--primary);
				background-color: var(--primary);
			}	
		}

	}

	
}

.layout_2l, .layout_2r{
	.blog_masonry{
		article{
			width: 50%;
			min-height: 200px;
			@media( max-width: 767px ){
				width: 100%;
			}
		}
	}
}


/*Blog Grid 2 ***************************************************/
.blog_grid_2{
	
	display: grid;
    gap: 25px;
    grid-template-columns: repeat(3, 1fr);

    @media( max-width: $desktop - 1 ){
		grid-template-columns: repeat(2, 1fr);    	
    }
    @media( max-width: 767px ){
    	grid-template-columns: repeat(1, 1fr);
    }

	article.post-wrap{
		position: relative;
    	margin-bottom: 0;
	    background: #fff;
	    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
	    text-align: center;
    	.post-media{
    		width: 100%;
    		margin-bottom: -20px;
    		img{
    			display: block;
				object-fit: cover;
				height: 280px;
    			width: 100%;
				@media(max-width: 767px){
					height: auto;
				}
    		}
		}

		ul.post-meta{
			background-color: var(--background);
		    display: inline-flex;
    		justify-content: center;
    		align-items: center;
    		width: 100%;
    		max-width: 280px;
   			padding: 12px;

    		li {
    			font-size: 12px;
    			font-weight: 500;
    		}

			.date{
				margin: 0;
			    position: absolute;
			    opacity: 1;
			    top: 20px;
			    left: 20px;
			    background-color: var(--primary);
			    color: #fff;
			    font-size: 11px;
			    font-weight: bold;
			    text-transform: uppercase;
			    padding: 13px 20px;
			}

			li:last-child{
				margin-right: 0px;
			}		
		}

		.post-info {
			padding: 0 50px 25px 50px;
            @media (max-width: 430px) {
                padding: 0 25px 25px 25px;
            }
		}

		.post-title{
			font-size: 22px;
			text-align: center;
		}

		.post-excerpt{
			font-size: 1em;
		}

		a.readmore{
			position: relative;
			display: inline-block;
			background-color: transparent;
			border: none;
			color: var(--text);
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 1px;
			text-align: center;
			padding: 0;
			margin: 0;
			&:hover{
				color: var(--primary);
			}

			&:after{
                content: '';
                width: 100%;
                height: 2px;
                display: block;
                background-color: var(--text);
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
            }

            &:before{
                @include transaction( all, 0.4s, ease);
                content: '';
                width: 0px;
                height: 2px;
                display: block;
                background-color: var(--primary);
                position: absolute;
                z-index: 2;
                bottom: 0;
                left: 0;
            }

            &:hover{
                &:before{
                    @include transaction( all, 0.4s, ease);
                    width: 100%;
                }
            }
		}
	}
}

.layout_2l, .layout_2r{
	.blog_grid_2{
		grid-template-columns: repeat(2, 1fr);
		 @media( max-width: 767px ){
	    	grid-template-columns: repeat(1, 1fr);
	    }
	}
}


/* Detail ***************************************************/
.single{
	article.post-wrap{
		position: relative;
		.post-media{
		    display: flex;
    		margin-bottom: 22px;

			img{
				width: 100%;
				max-height: 428px;
			    object-fit: cover;
			}
		}

		.post-meta{
			ul.post-meta{
				margin-bottom: 10px;
			}
		}
		.post-title{
			-webkit-hyphens: unset;
		    -moz-hyphens: unset;
		    -ms-hyphens: unset;
		    hyphens: unset;
		    margin-bottom: 13px;
		    margin-top: 0;
		    word-break: break-word;

		    &:hover{
				color: var(--heading);
			}
		}

		.post-content{
			margin-bottom: 20px;
			&:before,
			&:after{
				clear: both;
				content: '';
    			display: table;
			}
		}
		
		
		.post-tags{
			
			clear: both;
			margin-top: 50px;
			padding-top: 50px;
		    border-top: 1px solid #f3f3f3;
			a{
				text-transform: capitalize;
				margin: 0px 10px 10px 0px;
				display: inline-block;
				
				@include tag;
				background-color: var(--primary);
				color: #fff;
			}

			.post-tags-title{
				font-size: 20px;
				font-weight: 800;
				color: var(--heading);
				margin-right: 5px;
			}	
			
		}

		.post-tags-and-share {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			row-gap: 15px;
			padding: 30px 0;
		    border-top: 1px solid #f3f3f3;

            .post-tags{
				clear: both;
				margin-top: 0;
				padding-top: 0;
				border: unset;

				a{
					margin: 0px 10px 10px 0px;
					display: inline-block;
						
					@include tag;
					color: #fff;
					background-color: var(--primary);

					&:hover{
						background-color: var(--heading);
					}
				}		
			}

			.share-social-icons {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				row-gap: 10px;
				list-style: none;
			    padding: 0 0 0 10px;
			    margin: 0;
			    li {
			    	display: inline-block;
			    	&:not(:last-child) {
			    		margin-right: 12px;
			    	}
			    	a {
			    		@include transaction( all, 0.2s, ease );
			    		display: inline-flex;
				    	justify-content: center;
				    	align-items: center;
				    	background-color: #f5f5f5;
				    	width: 40px;
				    	height: 40px;
				    	border-radius: 50%;
			    		font-family: var(--secondary-font);
			    		font-weight: 400;
						font-size: 14px;
						line-height: 1.2;
						font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on;	
			    		color: var(--heading);
			    		&:hover {
	                        @include transaction( all, 0.2s, ease );
	                        background-color: var(--primary);
	                        color: #fff;
				    	}
			    	}
			    }
			}
		}

		.ova-next-pre-post {
			display: flex;
			@media (max-width: 767px) {
				flex-direction: column;
			}

			.pre, .next {
				@include transaction( all, 0.3s, ease );
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				width: calc(50% - 15px);
				padding: 60px;
				background-color: #f5f5f5;
				font-size: 22px;
				font-weight: 700;
				line-height: 1.2;
				color: var(--heading);
				@media (max-width: 767px) {
					width: 100%;
				}
				&:hover {
					@include transaction( all, 0.3s, ease );
					background-color: var(--primary);
					color: #fff;
				}
		    }

		    .none-pre {
		    	margin-right: 15px;
		    	width: calc(50% - 15px);
		    	@media (max-width: 767px) {
					width: 100%;
					margin-right: 0px;
				}
		    }

		    .pre {
				margin-right: 15px;
				word-break: break-word;
				
				@media(max-width: 767px){
					margin-right: 0px;
				}
			}
			.next {
				text-align: right;
			    margin-left: 15px;
			    word-break: break-word;
				@media (max-width: 767px) {
					flex-direction: row;
					text-align: left;
					margin-top: 30px;
					margin-left: 0px;
				}
			}
		}

		&.sticky{
			.post-tags{
				border-top: 1px solid #e1e1e1;
			}
		}
	}

	&.layout_1c{
		#main-content{
			max-width: 1000px;
			margin: 0 auto;
		}
	}
	
}


/*Page Title ***************************************************/

h1.page-title{
	margin-top: 0;
	-webkit-hyphens: unset;
    -moz-hyphens: unset;
    -ms-hyphens: unset;
    hyphens: unset;
}


.search{
	.page-header{
		.page-title{
			margin-bottom: 60px;
			border-bottom: 1px solid #e1e1e1;
    		padding-bottom: 15px;
			span{
				color: var(--primary);
			}
		}
	}

}