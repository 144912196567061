.ova-recent-post{
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    .item{
    	display: flex;
	    padding: 15px 20px;

    	.media{
    		margin-right: 15px;
    		a{
    			display: flex;
				position: relative;
    			overflow: hidden;
    			img{
    				width: 70px;
				    height: 70px;
				    object-fit: cover;
    			}
    		}
    	}

    	.info{
			display: flex;
			justify-content: space-between;
			align-items: start;
			flex-flow: column;

    		.post-title{
    			margin: 0;
    			font-size: 18px;
    			font-weight: 800;
    			line-height: 1.23;
    			font-family: var(--secondary-font);
    			display: -webkit-box;
			    -webkit-line-clamp: 2;
			    -webkit-box-orient: vertical;
			    overflow: hidden;
			    text-overflow: ellipsis;
    			a{
    				color: var(--heading);
					@include transaction( all, 0.3s, ease);

    				&:hover{
						@include transaction( all, 0.3s, ease);
    					color: var(--primary);
    				}
    			}
    		}
    		.item-meta{
    			line-height: 1;
    			.left {
    				color: var(--primary);
    			}
    			.post-author{
	    			color: var(--text);
	    			.left{
	    				margin-right: 5px;
	    			}
	    		}
    		}
    	}
    }
}