.ova-progress-circle-wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media(max-width: 350px){
        flex-flow: column;
        gap: 15px;
    }
    .ova-progress-circle {
        display: inline-flex;
        position: relative;

        .percent {
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 20px;
            font-weight: 600;
            color: var(--heading);
            line-height: 1;
            transform: translate(-50%, -50%);
            strong {
                font-weight: 600;
            }
        }
    }

    .title { 
        color: var(--heading);
        font-size: 18px;
        font-weight: 800;
        line-height: 1.6;
        text-align: left;
    }

}