.ova-contact-page{
	position: relative;
	input,textarea{
		font-size: 15px;
		font-weight: 500;
		font-family: var(--primary-font);
		&::placeholder {
		  color: var(--text);
		  opacity: 1; /* Firefox */
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		 color: var(--text);
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
		 color:var(--text);
		}
	}

	i{
		position: absolute;
		top: 28px;
    	display: flex;
		left: 0%;
		transform: translate(20px, -50%);
	}

	.text-email{
		display: flex;
		margin-bottom: 20px;
		position: relative;
		@media(max-width: 479px){
			flex-flow:column;
		}
		.ova-text{
			position: relative;
			width: 50%;
			overflow: hidden;
			flex: 0 0 50%;
			margin-right: 10px;
			@media(max-width: 479px){
				width: 100%;
				flex: 0 0 100%;
				margin-bottom: 20px;
				margin-right: 0px;
			}	
		}
		.ova-email{
			position: relative;
			width: 50%;
			overflow: hidden;
			margin-left: 10px;
			@media(max-width: 479px){
				width: 100%;
				margin-left: 0px;
			}
		}
		input{
			width: 100%;
			border-color: transparent;
			background-color: var(--background);
			padding: 15px 30px;
		    border-radius: unset;

			@media(max-width: 479px){
				padding-left: 20px;
			}

		}
	}

	.ova-textarea{
		margin-bottom: 20px;
		position: relative;
		i{
		    position: absolute;
		    top: 30px;
		    left: 0%;
		    transform: translate(20px, -50%);
		}

		textarea{
			max-height: 168px;
			width: 100%;
			border-color: transparent;
			background-color: var(--background);
			padding: 24px 15px;
			padding-left: 30px;
		    border-radius: unset;

			@media(max-width: 479px){
				padding-left: 20px;
			}
		}
	}
	.ova-submit{
		text-align: center;
		.wpcf7-spinner{
			position: absolute;
			@media(max-width: 350px){
				margin: 0;
			}
		}
		button{
			padding: 20px 47px;
		    font-size: 12px;
    		font-weight: 800;
    		letter-spacing: 2px;
    		text-transform: uppercase;
			font-family: var(--primary-font);
		    border-radius: unset;
		    border: unset;

		    &:hover{
		    	background-color: var(--primary);
		    	color: #ffffff;
		    }
		    position: relative;
		    span{
		    	position: relative;
		    	z-index: 2;
		    }
		    &:before{
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: calc(100% );
                -webkit-transform-origin: top;
                transform-origin: top;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transform: scaleY(0);
                transform: scaleY(0);

               	background-color: var(--heading);
            }

            &:hover{
                &:before{
                    transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }
		}
	}

	&.ova-faq-page{
		.ova-submit{
			text-align: left;
		}
	}
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output, .wpcf7 form.payment-required .wpcf7-response-output{
	border-color: #dc3232;
	color: #dc3232;
}