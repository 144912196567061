.header_sticky {

    &.sticky_bg_dark {
        &.active_sticky {
            background-color: #13223c;
        }
    }

    &.active_sticky{
  	    position: fixed;
  	    top: 0;
  	    left: 0;
  	    width: 100%;
  	    z-index: 9999;
        background-color: #fff;
  	    @include transaction( all, 0.3s, linear );
    }

}

.elementor-column.elementor-element.ova-sticky-column {
    position: sticky;
    top: 150px;
    height: fit-content;
    @media (max-width: 1024px) {
        position: static;
    }
}