// Transaction
@mixin transaction( $propertty, $duration, $timing ){
	transition: $propertty $duration $timing;
    -moz-transition: $propertty $duration $timing;
    -webkit-transition: $propertty $duration $timing;
    -o-transition: $propertty $duration $timing;
}

//Tag - used for a tag
@mixin tag(){
	background-color: #ffffff;
	color: var(--heading);
	border-radius: 0px;
	padding: 5px 15px;
	text-transform: uppercase;
	font-weight: 800;
	letter-spacing: 1px;
	font-size: 12px !important;
	@include transaction( all, 0.3s, linear );

	&:hover{
		background-color: var(--primary);
		color: #fff;
	}
}