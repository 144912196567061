.ova-testimonial-2{

    .slide-for {
        @media (max-width: 1024px) {
        	display: none;
        }

    	.slick-track {
    		display: inline-block;
    		margin: 0;
    		@media (max-width: 520px) {
		    	display: none;
			}
    	}
    	.slick-active {
    		opacity: 1 !important;  
    	}
        .small-img {
        	position: relative;
	        overflow: hidden;
	        border-radius: 50%;
	    	img{
				display: block;
				width: 75px;
				height: 75px;
				object-fit: cover;
				margin: 0 5px;
				border-radius: 50%;
			}
	    }
	    .slick-current {
			.small-img {
    			img {
    				border: 2px solid var(--primary);
    			}
    		}
    	}
    }

	.slide-testimonials-2{

		&.slick-slider {
			margin-bottom: 0;
		}

		.client-info{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-top: 42px;

			@media (max-width: 1024px) {
				flex-direction: column;
				margin-top: 0px;
			}

			.client{
				position: relative;
				border-radius: 50%;
			    flex: 0 0 30%;
			    width: 30%;

			    @media(max-width: 1024px){
                	flex: 0 0 100%;
                	width: 100%;
                }
				img{
					display: block;
					width: 154px;
					height: 154px;
					object-fit: cover;
					border-radius: 50%;
					position: relative;
					z-index: 1;
					@media (max-width: 1024px) {
			        	margin-left: 0px;
			        	margin-bottom: 30px;
			        }
				}

				&:after{
					content: '';
					display: block;
					width: 154px;
					height: 154px;
					position: absolute;
					z-index: 0;
					top: 10px;
					left: 10px;
					border-radius: 50%;
					background-color: var(--primary);
				}
			}

			.info {
                flex: 0 0 70%;
                width: 70%;
                @media(max-width: 1024px){
                	flex: 0 0 100%;
                	width: 100%;
                }
                .icon-star{
					margin-bottom: 6px;
					line-height: 1;
					i{
						display: inline-flex;
						font-size: 14px;
						color: var(--primary);
					}
				}

				p.ova-evaluate{
					margin: 0;
					font-family: var(--secondary-font);
					font-size: 18px;
					font-weight: 500;
					color: var(--text);
					line-height: 1.9;
					padding-bottom: 27px;
				}

				.name-job{
					position: relative;
					display: block;
					.name{
						margin: 0 0 6px 0;
						font-family: var(--secondary-font);
						font-size: 24px;
						line-height: 1.2;
						font-weight: 800;
						color: var(--heading);
					}
					.job{
						margin: 0;
						font-size: 12px;
						font-weight: 500;
						line-height: 1.2;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: var(--text);
					}
				}
				
				.icon {
					position: absolute;
					right: 0px;
					bottom: 0px;
				    display: flex;
				    justify-content: right;
				    align-items: center;
					i {
						font-size: 60px;
						color: var(--primary);
						display: inline-flex;
					}

					@media(max-width: 767px){
						display: none;
					}
				}		
			}
		}

		.slick-prev, .slick-next {
			@include transaction( all, 0.3s, ease);
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 25px;
			background-color: #ffffff;
			border-radius: 50%;
			top: unset;
			bottom: -30px;
			z-index: 1;
			overflow: hidden;
			&:before {
				@include transaction( all, 0.3s, ease);
				font-family: flaticon !important;
				font-size: 14px;
				color: var(--heading);
				position: relative;
				z-index: 2;
			}
			&:hover {
				@include transaction( all, 0.3s, ease);
				&:before {
					@include transaction( all, 0.3s, ease);
					color: #ffffff;
				}
			}

			&:after{
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                -webkit-transform-origin: top;
                transform-origin: top;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                background-color: var(--heading);
            }

            &:hover{
                &:after{
                    transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }
		}

		.slick-prev {
			left: unset;
			right: 62px;
			bottom: 100%;

			@media (max-width: 1024px) {
				top: 30px;
			}
			&:before {
				content: "\f105";
				line-height: 0;
			}
		}

		.slick-next {
			right: 0;
			bottom: 100%;

			@media (max-width: 1024px) {
				top: 30px;
			}
			&:before {
				content: "\f104";
				line-height: 0;
			}
		}

		.slick-dots {
			z-index: 1;
			display: flex;
			justify-content: flex-end;
			bottom: 10px;
			list-style-type: none;
			
			li {
				margin: 0 3px;
				width: auto;
				height: auto;
				&.slick-active {
					button {
						opacity: 1;
					}
				}
				button {
					display: block;
					width: 9px;
					height: 9px;
					padding: 0;	
					background-color: var(--heading);
					opacity: 0.3;
					border: none;
					border-radius: 50%;
					text-indent: -9999px;
				}
			}	
		}

	}

}