.ova-contact-info-box{
	position: relative;
	.icon{
		color: var(--primary);
		font-size: 64px;
	    position: absolute;
    	bottom: 65px;
    	right: 40px;
	}

	.contact{
		border: 1px solid var(--light);
		border-bottom: unset;
		padding: 40px;
		padding-bottom: 59px;
		padding-right: 48px;
	    padding-top: 35px;
    	padding-left: 48px;

		.label{
			font-size: 20px;
			margin: 0 0px 14px 0px;
			padding: 0;
			font-weight: 800;
			line-height: 1.2em;
			color: var(--heading);
		}

		.info{
			list-style-type: none;
			padding: 0;
			margin: 0;

			.item{
				color: var(--text);
				a{
					color: var(--text);
					-webkit-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
					position: relative;

					&:before{
						position: absolute;
					    content: '';
					    width: 0%;
					    height: 1px;
					    left: 0px;
					    bottom: 0px;
					    transition: all 500ms ease;
					    background-color: var(--primary);
					}

					&:hover{
						color: var(--primary);
						&:before{
							width: 100%;
						}
					}
				}
			}
		}

	}

	.contact-btn{
		display: flex;
		border-right: 1px solid var(--light);
		position: relative;
    	overflow: hidden;
		.button-mask{
			position: relative;
			width: 55%;
			flex: 0 0 55%;

		    border-top: 1px solid var(--light);

	        &:after{
	        	content: "";
			    width: 1.2px;
			    height: calc(101%);
			    display: block;
			    position: absolute;
			    z-index: -1;
			    bottom: 0;
			    left: calc(100% + 14px);
			    background: var(--light);
			    transform: skew(30deg);
	        }

	        &:before{
        	    content: "";
			    width: 30px;
			    height: calc(100%);
			    display: block;
			    position: absolute;
			    z-index: 2;
			    bottom: 0;
			    left: calc(100%);
			    background: #ffffff;
			    clip-path: polygon(0 0, -50% 150%, 100% 100%);
	        }
		}
		.btn-contact{
			width: 45%;
			flex: 0 0 45%;
			text-align: right;
		    border-radius: unset;
		    background-color: #dfe3ea;
		    margin-top: 15px;
		    position: relative;
    		padding: 5px 45px 5px 45px;

		    .text{	
	    	    font-size: 12px;
			    font-weight: bold;
			    text-transform: uppercase;
			    letter-spacing: 1px;
			    position: relative;
			    z-index: 2;
			    color: var(--text);
			    transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
		    }

		    &:before{
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: calc(100% + 1px);
                background-color: var(--primary);
                -webkit-transform-origin: top;
                transform-origin: top;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
            }

            &:hover{
                &:before{
                    transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }


		}
	}
	
	&:hover{
		.contact{
			border-color: var(--primary);
		}
		.contact-btn{
			border-right-color: var(--primary);
			.button-mask{
				border-top-color: var(--primary);
				&:after{
					background-color: var(--primary);
				}
			}

			.btn-contact{

				&:before{
                    transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
				}

				.text{
					color: #ffffff;
				}
			}
		}
	}
}