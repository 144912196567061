.ova-team-slider {
	.team-slider-wrapper {
		&.owl-carousel {
			.owl-nav {
				position: relative;
				display: flex;
				justify-content: center;
				margin-top: 80px;
				@media (max-width: 767px) {
					margin-top: 40px;
				}
				&:before {
					position: absolute;
					content: "";
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: calc(50% - 56px);
					height: 1px;
					background-color: rgb(223, 227, 234);
				}

				&:after {
					position: absolute;
					content: "";
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: calc(50% - 56px);
					height: 1px;
					background-color: rgb(223, 227, 234);
				}

				button {
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 0;
					width: 28px;
					height: 28px;	
					background-color: var(--background);

					i {
						display: inline-flex;
						font-size: 9px;
						color: var(--text);
					}

					&:first-child {
						margin-right: 8px;
					}

					&:hover {
						background-color: var(--primary);
						i {
							color: #fff;
						}
					}
				}
			}
		}
	}
}