.wrap_header_banner{
	width: 100%;
	position: relative;

	&.left{
		ul.breadcrumb{
			justify-content: flex-start;
		}
	}
	&.right{
		ul.breadcrumb{
			justify-content: flex-end;
		}
	}
	&.center{
		ul.breadcrumb{
			justify-content: center;
		}
	}

	ul.breadcrumb{
		margin: 0;
		padding: 0;
		li .separator i{
			opacity: 0.6;
		}
	}
	
	&.bg_feature_img{
		background-size: cover!important;
		background-repeat: no-repeat!important;
		background-position: center center!important;
	}
	.cover_color{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.header_banner_el{
		position: relative;
		z-index: 1;
	}	

	/*title*/
	.header_title{
		margin: 0;
		padding: 0;
		font-size: 26px;
		text-transform: uppercase;
		line-height: 1.1em;
		font-weight: 500;
	}
}