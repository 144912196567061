.page_404{
	.message{
		margin-top: 0;
		padding-top: 0;
	}
}

.ova_404_page {
	text-align: center;
	padding: 0 30px;
	margin-top: 90px;

	.title-404 {
		margin: 0;
		font-size: 350px;
		font-weight: 400;
		line-height: 1;
		color: var(--primary);

		@media screen and (max-width: 666px) {
			font-size: 170px;
		}
		@media screen and (max-width: 400px) {
			font-size: 130px;
		}
	}

	.title {
		margin-bottom: 29px;
		font-size: 40px;
		line-height: 1.2;
		font-weight: 500;
		color: var(--heading);
	}

	.description {
		margin-bottom: 60px;
		font-size: 20px;
		line-height: 1.2;
		color: var(--text);
	}

	form {
		position: relative;
		width: 100%;
  		height: 80px;
  		max-width: 550px;
  		display: inline-block;
  		margin-bottom: 40px;

		&:before {
		    font-size: 24px;
		    color: var(--heading);
		    font-family: "ovaicon";
		    content: "";
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    display: inline-block;
		    font-style: normal;
		    font-variant: normal;
		    font-weight: normal;
		    line-height: 1;
		    vertical-align: middle;
		    position: absolute;
		    top: 50%;
		    right: 50px;
		    line-height: 1;
		    transform: translate(0, -50%);
		    cursor: pointer;

		}

		input[type=search] {
		    width: 100%;
		    height: 100%;
		    border: none;
		    border-radius: 0;
		    background-color: #e9edf2;
		    font-size: 16px;
		    line-height: 1.2;
		    color: var(--text);
		    padding-left: 50px;
		}

		input[type=submit] {
			position: absolute;
		    right: 0px;
		    top: 0;
		    background-color: transparent;
		    border: none;
		    font-size: 0;
		    width: 110px;
		    height: 100%;
		}
	}

	.ova-go-home {
		margin-bottom: 120px;

		a {
			display: inline-block;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.333;
			color: #FFFFFF;
			padding: 24px 70px;
			background-color: var(--primary);
			border-radius: 100px;
			-webkit-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-ms-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;

			&:hover {
				background-color: var(--heading);
			}
		}
	}
}