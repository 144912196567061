.ova-hide-element-1600{

	@media( max-width: 1600px ) {
		display: none !important;
	}

}

.ova-hide-element-1500{

	@media( max-width: 1500px ) {
		display: none  !important;
	}

}

.ova-hide-element-1400{

	@media( max-width: 1400px ) {
		display: none  !important;
	}

}

.ova-hide-element-1300{

	@media( max-width: 1300px ) {
		display: none  !important;
	}

}

.ova-hide-element-1200{

	@media( max-width: 1200px ) {
		display: none  !important;
	}

}

.ova-hide-element-min-1200{

	@media( min-width: 1200px ) {
		display: none  !important;
	}

}