.ova-video {
	display: block;
	position: relative;
	z-index: 2;

	&.video-image{
		.image{
			display: flex;
			img{
				width: 100%;
				max-width: 470px;
    			height: 530px;
				object-fit: cover;
                @media (max-width: 767px) {
                	height: auto;
                	max-width: 100%;
                }
			}
		}
		.icon-content-view{
			.content{
				margin: 0;
			}
			&.video_active{
				position: absolute;
				bottom: 0px;
				left: 0;
			}
		}
	}

	.icon-content-view{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
		flex-wrap: nowrap;

		&.video_active {
			cursor: pointer;
		}

		.content {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: nowrap;
			width: 95px;
			height: 95px;
			background:  var(--primary);
			-moz-transition: all .3s ease;
			-webkit-transition: all .3s ease;
			transition: all .3s ease;
			@media (max-width: 767px) {
            	width: 64px;
            	height: 64px;
            }
		
			i {
				font-size: 30px;
				z-index: 1;
				color: #ffffff;
			    display: flex;
			    transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;	
			}

			&:before{
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                -webkit-transform-origin: top;
                transform-origin: top;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                background-color: var(--heading);
            }

            &:hover{
                &:before{
                    transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }	
		}

		.ova-text {
			margin: 0;
			font-weight: 500;
			font-size: 20px;
			line-height: 1.4;
			color: var(--heading);
			margin-left: 16px;

			a {
				color: var(--heading);
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
			}
		}
	}

	&.version_2{
		display: flex;
		justify-content: center;
		&.video-image{
			.icon-content-view{
				position: relative;

				&.video_active{
					position: relative;
					.content{
						border-radius: 0 0 100px 100px;
						position: relative;
						top: 50%;
	                	left: 50%;
	                	transform: translate(-50%, -50%);
						z-index: 2;
					    width: 165px;
						height: 165px;
						&:before{
							content: unset;
						}
					}
				}
			} 
		}
	}

	&.version_3{
		display: flex;
		justify-content: center;
		&.video-image{
			.icon-content-view{
				position: relative;
				border: 10px solid var(--background);
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;

				&:hover{
					-moz-transition: all .3s ease;
					-webkit-transition: all .3s ease;
					transition: all .3s ease;
					&:after{
						-moz-transition: all .3s ease;
						-webkit-transition: all .3s ease;
						transition: all .3s ease;
					}
				}

				&:after{
					content: '';
					width: 20px;
				    height: 23px;
				    display: block;
				    position: absolute;
			        z-index: 2;
			        top: calc( 100% + 10px);
    				left: calc(100% - 10px);
				    background: var(--background);
					clip-path: polygon(0 0, 0% 100%, 100% 0);
					-moz-transition: all .3s ease;
					-webkit-transition: all .3s ease;
					transition: all .3s ease;
				}
				.content{
					background-color: #ffffff;
					width: 115px;
					height: 115px;
					position: relative;
					i{
						color: var(--primary);
						-webkit-animation: lineScale 2.5s linear infinite;
			    		-moz-animation: lineScale 2.5s linear infinite;
						animation: lineScale 2.5s linear infinite;
						position: absolute;

					    top: 50%;
					    left: 50%;
					    transform: translate(-50%, -50%);

					    @media( max-width: 767px ) {	
							-webkit-animation: unset;
			    			-moz-animation: unset;
							animation: unset;
						}
					}
				}
			}
		}
	}
	
	.modal-container {
		display: none;
		background-color: rgba( 0, 0, 0, .2 );
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 10;

		.modal {
		    width: 900px;
    		height: 500px;
    		position: relative;

    		@media screen and (max-width: 900px) {
    			width: 768px;
    			height: 450px;
    		}

    		@media screen and (max-width: 788px) {
    			width: 600px;
    			height: 350px;
    		}

    		@media screen and (max-width: 620px) {
    			width: 400px;
    			height: 250px;
    		}

    		@media screen and (max-width: 420px) {
    			width: 320px;
    			height: 200px;
    		}

    		@media screen and (max-width: 330px) {
    			width: 300px;
    			height: 180px;
    		}

    		i {
			    position: absolute;
			    right: -10px;
    			top: -35px;
    			padding: 10px;
    			cursor: pointer;
			    font-size: 12px;
			    color: #fff;
			    font-weight: 600;
			    -moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;

				&:hover {
					color: #000;
				}
    		}

    		.modal-video {
    			width: 100%;
    			height: 100%;
    		}
		}
	}

	@keyframes lineScale {
		50% { 
	        -webkit-transform: translate(-50%, -50%) scale(1.5);
	        -moz-animation: translate(-50%, -50%)scale(1.5);
	        transform: translate(-50%, -50%) scale(1.5);
	    }
	    50% { 
	        -webkit-transform: translate(-50%, -50%) scale(0.8);
	        -moz-animation: translate(-50%, -50%)scale(0.8);
	        transform: translate(-50%, -50%) scale(0.8);
	    }
	}
}
