.wrap_header_banner-2{
	width: 100%;
	position: relative;

	&.left{
		ul.breadcrumb{
			justify-content: flex-start;
		}
	}
	&.right{
		ul.breadcrumb{
			justify-content: flex-end;
		}
	}
	&.center{
		ul.breadcrumb{
			justify-content: center;
		}
	}

	ul.breadcrumb{
		margin: 0px;
		padding: 0px;
		li {
			font-size: 12px;
			line-height: 1.6;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 2px;
			.separator i{
				opacity: 0.6;
			}
		}
	}
	
	&.bg_feature_img{
		background-size: cover!important;
		background-repeat: no-repeat!important;
		background-position: center center!important;
	}
	.cover_color{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		height: 100%;
	}

	.header_banner_el{
		position: relative;
		z-index: 1;
	}

	/*title*/
	.title-box{
		.header_title {
			margin: 0px;
			padding: 0px;
			font-size: 26px;
			text-transform: uppercase;
			line-height: 1.1em;
			font-weight: 500;

		    max-width: var(--container-width);
			text-align: left;
			margin: 0 auto;
		}
	}
	

	.header_breadcrumbs{
		display: flex;

		.bg-breadcumb{

			flex: 0 0 70%;
			width: 70%;
			background-color: #ffffff;
			height: auto;
			position: relative;

			@media(max-width: 1400px){
				flex: 0 0 50%;
				width: 50%;
			}

			@media(max-width: 1024px){
				flex: 0 0 0%;
				width: 0%;
			}

			&:after{
			    content: "";
			    width: 54px;
			    height: calc(100%);
			    display: block;
			    position: absolute;
			    z-index: 2;
			    bottom: 0;
			    left: calc(100%);
			    background: #ffffff;
			    clip-path: polygon(0 0, -50% 150%, 100% 100%);

			    @media(max-width: 1024px){
					content: unset;
				}
			}

		}

		#breadcrumbs{
			width: 30%;
			flex: 0 0 30%;
		    display: flex;
    		justify-content: start;
    		align-items: center;
		    background: var(--primary);
    		margin-top: 30px;
    		padding-left: 100px;
		    padding-top: 8px;
    		padding-bottom: 8px;

    		@media(max-width: 1400px){
				flex: 0 0 50%;
				width: 50%;
			}

			@media(max-width: 1024px){
				flex: 0 0 100%;
				width: 100%;
				margin-top: 0px;
				padding-left: 20px;
			}
		}

	}
}