.ova-testimonial{

	.owl-stage-outer{
		padding: 50px 0;
		margin-top: -50px;
		@media(max-width: 767px){
			padding-bottom: 20px;
		}
	}

	.owl-carousel .owl-stage-outer {
	    @media(max-width: 1290px){
	    	margin-right: 0px;
	    	margin-left: 0px;
	    	padding-left: 0px;
		    padding-right: 0px;
		    width: 100%;
	    }
	}

	.slide-testimonials{

		.owl-item{
			.client_info{
				background-color: #ffffff;
			}
		}

		.owl-item.active{
			.client_info{
				box-shadow: 0px 10px 40px 0px rgba(6, 22, 58, 0.1);
			}
		}
		.client_info{
			padding: 35px 35px 40px 40px;
			position: relative;
			.icon-quote{
				position: absolute;
				i{
					display: inline-block;
					&:before{
						margin: 0;
						line-height: 1;
					}
				}
			}		
			.evaluate{
				font-size: 16px;
				font-weight: 500;
				color: var(--text);
				line-height: 26px;
				margin: 0;
			}
			.info{
				padding-top: 24px;
				display: flex;
				align-items: center;
				.client{
					margin-right: 15px;
					img{
						width: 100px;
						height: 100px;
					    object-fit: cover;
					}
				}
				.name-job{
					p{
						margin: 0;
					}
					.name{
						line-height: 1.2;
						color: var(--heading);
						margin-bottom: 2px;
						font-size: 24px;
						font-weight: 800;
					}
					.job{
						line-height: 1.2;
						color: var(--text);
						font-size: 14px;
						font-weight: 500;
					}
				}

				.ova-rating{
					justify-content: center;
					line-height: 1;
					.elementor-star-rating{
						font-size: 16px;
						color: var(--primary);
						font-family: eicons;
						display: inline-flex;
						padding-bottom: 15px;
					    gap: 2px;

						.elementor-star-full{
							&::before{
								content: "\e934";
								display: block;
								font-size: inherit;
								font-family: inherit;
								position: absolute;
								color: var(--primary);
								top: 0;
								left: 0;
							}
						}

						i{
							display: inline-block;
							position: relative;
							font-style: normal;
							cursor: default;
							line-height: 100%;
							vertical-align: middle;
							font-family: eicons;
							&::before{
								display: block;
								font-size: inherit;
								font-family: inherit;
								position: absolute;
								overflow: hidden;
								color: var(--primary);
								top: 0;
								left: 0;
							}
						}
						i.elementor-star-1{
							&::before{
								content: "\e934";
								width: 10%;
							}
						}
						i.elementor-star-2{
							&::before{
								content: "\e934";
								width: 20%;
							}
						}
						i.elementor-star-3{
							&::before{
								content: "\e934";
								width: 30%;
							}
						}
						i.elementor-star-4{
							&::before{
								content: "\e934";
								width: 40%;
							}
						}
						i.elementor-star-5{
							&::before{
								content: "\e934";
								width: 50%;
							}
						}
						i.elementor-star-6{
							&::before{
								content: "\e934";
								width: 60%;
							}
						}
						i.elementor-star-7{
							&::before{
								content: "\e934";
								width: 70%;
							}
						}
						i.elementor-star-8{
							&::before{
								content: "\e934";
								width: 80%;
							}
						}
						i.elementor-star-9{
							&::before{
								content: "\e934";
								width: 90%;
							}
						}
					}
				}

				.ova-rating.star_unicode{
					.elementor-star-rating{
						i.elementor-star-1,
						i.elementor-star-2,
						i.elementor-star-3,
						i.elementor-star-4,
						i.elementor-star-5,
						i.elementor-star-6,
						i.elementor-star-7,
						i.elementor-star-8,
						i.elementor-star-9{
							&::before{
								content: "\002605";
							}
						}
						.elementor-star-full{
							&::before{
								content: "\002605";
							}
						}
					}
				}
			}
		}
		.owl-dots{
			text-align: center;
			margin-top: 2px;
			.owl-dot{
				outline: none;
				span{
					width: 6px;
					height: 6px;
					margin: 5px;
					background: #ffffff;
					display: block;
					-webkit-backface-visibility: visible;
					transition: opacity .2s ease;
					border-radius: 30px;
				}
				&.active{
					span{
						width: 12px;
						border-radius: 5px;
						opacity: 1;
					}
				}
			}
		}
	}

	&.version_1{
		.slide-testimonials{
			.owl-item{
				.client_info{
					position: relative;

					.decor-geometry {
			            @include transaction( all, 0.5s, ease);
			            position: absolute;
			            top: 0;
			            right: 0;
			            width: 120px;
			            height: 140px;
			            border-bottom-left-radius: 140px;
			            background-color: var(--background);
			    
			            @media (max-width: 767px) {
			                width: 70px;
			                height: 90px;	 
			            }
			            &:before {
			                position: absolute;
			                content: "";
			                top: 0;
			                right: 0;
			                width: 100px;
			                height: 138px;
			                border-bottom-left-radius: 138px;
			                background-color: var(--heading);
			                @media (max-width: 767px) {
			                    width: 50px;
			                    height: 88px;
			                }
			            }
			        }

					.info {
						.name-job{
							.name{	
							    margin-bottom: 5px;
							}
						}	
					}

					&:before{
						content: '';
					    display: block;
					    width: calc(100% - 30px);
					    height: calc(100% + 20px);
					    position: absolute;
					    z-index: -1;
					    top: -10px;
					    left: 15px;
					    background-color: rgb(255, 255, 255);
					    opacity: 0.25;
					}

					&:after{
						content: '';
					    display: block;
					    width: calc(100% - 70px);
					    height: calc(100% + 40px);
					    position: absolute;
					    z-index: -2;
					    top: -20px;
					    left: 35px;
					    background-color: rgb(255, 255, 255);
					    opacity: 0.1;
					}
				}
			}
		}
		.item{
			
			.client_info{

			    @media(max-width: 768px){
			    	padding: 0px 20px 20px 20px;
			    }
			    
				.info{
				    align-items: start;
			        padding-bottom: 23px;
					padding-left: 30px;
					@media(max-width: 400px){
					    flex-direction: column;
					}
					.client{
						position: relative;
					    margin-right: 45px;
						@media(max-width: 400px){
					    	margin-bottom: 35px;
					    }
						img{
							max-width: 100px;
							height: 100px;
							position: relative;
							z-index: 1;
							display: block;
						}

						&:after{
							content: '';
							display: block;
							position: absolute;
							top: 10px;
							right: -10px;
							background-color: var(--heading);
						    width: 100%;
						    height: 100%;
						    z-index: 0;
						}

						.icon-quote{
							display: flex;
							z-index: 2;
						    bottom: 20px;
						    left: -30px;
						    width: 40px;
						    height: 40px;
						    justify-content: center;
						    align-items: center;
						    color: #fff;
						    background-color: var(--primary);

							i{
								font-size: 14px;
							}

							&:after{
								content: '';
								width: 30px;
							    height: 20px;
							    display: block;
							    position: absolute;
						        z-index: 2;
							    left: 0;
							    top: calc(100%);
							    background: var(--primary);
							    opacity: 0.49;
								clip-path: polygon(0 0, 100% 0, 100% 100%);
							}
						}

					}
				}
			}
		}
	}

	&.version_2{
		.slide-testimonials {

			.owl-stage-outer{
			    padding-top: 50px;
			    @media (max-width: 767px) {
			    	padding-top: 30px;
			    }
			}

			.client_info{
				padding: 40px 40px 60px 40px;
				background-image: url(./assets/img/elementor/bg-testimonial.png);
				background-position: bottom center; 
				background-repeat: no-repeat; 
				background-size: contain;
				@media(max-width: 767px){
					padding: 30px 25px 35px 25px;
				}
				.info{
					position: relative;
					z-index: 2;
			    	justify-content: space-between;
			    	padding-top: 0;
			        margin-bottom: 30px;
			        @media(max-width: 767px){
						display: block;
					}

					.client{
						position: relative;
					    margin-top: -100px;

					    @media(max-width: 767px){
					    	margin: 30px 0;
					    }
					    
						img{
							width: 150px;
							height: 150px;
							position: relative;
							z-index: 1;
							@media (max-width: 767px) {
								width: 100px;
								height: 100px;
							}
						}
						
						&:after{
							content: '';
							position: absolute;
							z-index: 0;
							top: 10px;
							left: 10px;
							background-color: var(--heading);
							width: 150px;
							height: 150px;
                            @media (max-width: 767px) {
								width: 100px;
								height: 100px;
							}
						}
					}
					.name-job{
						.name{
							margin-bottom: 7px;
							padding-right: 10px;
						}
						.job{
							text-transform: uppercase;
							font-size: 12px;
						}
					}

					.ova-rating{
						.elementor-star-rating{
							padding-bottom: 4px;
						}
					}
				}
				.icon-quote{
					bottom: 26px;
					right: 30px;
					font-size: 110px;
					opacity: 0.03;
					z-index: 1;
					color: var(--heading);
				}
				.evaluate{
					position: relative;
					z-index: 2;
					font-size: 18px;
    				font-weight: 500;
    				padding-bottom: 20px;
				}
			}
		}
	}

	&.version_3{
		.slide-testimonials{
			.owl-stage-outer{
				padding-bottom: 80px;
			}
			.owl-item{
				&.active{
					.client_info{
						box-shadow: unset;
					}
				} 

				.client_info{
					padding: 0;
			    	background-color: transparent;
					.icon-quote{
						position: relative;
					}
					.evaluate{
					    margin-top: -5px;
					}
				}
			}

			.owl-nav{	
				position: absolute;
				bottom: -30px;
			    right: 0;
				display: flex;
				&.disabled{
					display: none;
				}
				@media(max-width: 1024px){
				    right: unset;
				    display: flex;
				}
				button{
					width: 50px;
					height: 50px;
					padding: 0px;
					border-radius: 0;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					border: unset;
					background-color: transparent;
					position: relative;
					overflow: hidden;
					&:before{
                        transition: all 0.5s ease;
                        -moz-transition: all 0.5s ease;
                        -webkit-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        -webkit-transform-origin: top;
                        transform-origin: top;
                        -webkit-transform-style: preserve-3d;
                        transform-style: preserve-3d;
                        -webkit-transform: scaleY(0);
                        transform: scaleY(0);
                        background-color: var(--heading);
                    }

                    &:hover{
                        &:before{
                            transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -webkit-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            -webkit-transform: scaleY(1);
                            transform: scaleY(1);
                        }
                    }

					i{
						@include transaction( all, 0.3s, linear);
						font-size: 14px;
						display: flex;
						position: relative;
						z-index: 3;
					}

					&.owl-prev{
						position: relative;
						z-index: 2;
						bottom: 30px;
					    left: 19px;
						background-color: #ffffff;
						i{
							color: var(--heading);
						}
					}

					&.owl-next{
						background-color: var(--primary);
						i{
							color: #ffffff;
						}
					}

					&.owl-prev, &.owl-next{
						
						&:hover{
							i{
								color: #ffffff ;
							}
						}
					}
				}
			}
		} 

		.item{
			.client_info{
				.quote-box{
				    gap: 24px;
					display: flex;
				    align-items: start;
				    .icon-quote{
				    	i{
				    		font-size: 42px;
			    		    color: #fff;
				    	}
				    }
				}

				.info{
					.client{
						margin-right: 0;
						position: relative;
						img{
						    height: 70px;
						    width: 70px;
						}

						&:after{
							content: '';
							width: 100%;
							height: 100%;
							background-color: var(--primary);
							display: block;

							position: absolute;
							z-index: -1;
							top: 10px;
							left: 10px;
						}
					}
					.name-job{
						margin-left: 40px;
						.name{
							font-size: 20px;
							margin-bottom: 5px;
						}
					}

					.ova-rating {
						.elementor-star-rating {
							padding-bottom: 9px;
						}
					}
				}
			}
		}
	}

}


.testimonial_version_1 {
	.owl-theme{	
		.custom-nav{
			display: flex;
			gap: 10px;
			button{
				width: 63px;
				height: 63px;
				border-radius: 50%;
				padding: 0px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				background-color: transparent;

				i{
					@include transaction( all, 0.3s, linear);
					font-size: 20px;
					display: flex;
					color: #d0752c;
				}

				&:hover{
					i{
						@include transaction( all, 0.3s, linear);
						color: #ffffff;
					}
				}

				&.owl-prev, &.owl-next{
					border: 2px solid #d0752c;
					@media( max-width: 1024px ){
						border-color: #ffffff;
						i{
							color: #ffffff;
						}
					}
					&:hover{
						border-color: #ffffff;
					}
				}
			}	
		}
	}
}