.ova-header-customize-triangle{	
	&:after{
		content: '';
		width: 68px;
	    height: calc(100%);
	    display: block;
	    position: absolute;
        z-index: 2;
	    top: 0;
	    left: calc(100% - 1px);
	    background: var(--heading);
		clip-path: polygon(0 0, 0% 100%, 100% 100%);
		@media( max-width: 1024px ) {	
			display: none;
		}
	}
}


.ova-footer-customize-triangle {
	&:before{
		content: '';
		width: 17px;
	    height: 2px;
	    display: block;
	    position: absolute;
	    bottom: -10px;
	    left: 0;
	    background: var(--primary);
	}
	&:after {
		content: '';
		width: 7px;
	    height: 2px;
	    display: block;
	    position: absolute;
	    bottom: -10px;
	    left: 22px;
	    background: #fff;
	}
}