html{
    overflow-x: hidden;
}

*, *::before, *::after {
    box-sizing: border-box;
}


body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--text);
    font-family: var(--primary-font);
    letter-spacing: var(--letter-spacing);

    @media (max-width: 1024px) {
        overflow-x: hidden;
    }

    &.admin-bar{
        top: 32px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6{
    color: var(--heading);
    font-family: var(--secondary-font);
    font-weight: bold;
    clear: both;
    margin: 1em 0 0.5em 0px;
}


h1{
	font-size: 2em;
    line-height: 1.21;
    
    
}

h2{
	font-size: 1.7411em;
     line-height: 1.21;
   
    
}

h3{
	
	font-size: 1.5157em;
    
   
    
}

h4{
	font-size: 1.3195em;
    
   
    
}

h5{
	font-size: 1.1487em;
    
    
    
}

h6{
	font-size: 1em;
    
    
    
}

a{
    text-decoration: none;
    color: var(--primary);
    &:hover,
    &:focus{
        text-decoration: none; 
        outline: none;
    }
}

p{
    line-height: var(--line-height);
    margin: 0 0 2em;

    &:empty{
        height: 0;
        margin: 0;
    }
    
}

.elementor-text-editor p{
    line-height: inherit;
    margin: 0;
}

blockquote {
    position: relative;
    font-weight: 600;
    color: var(--heading);
    padding: 20px 60px 20px;
    margin: 0;
    font-size: 1.2em;
    font-style: italic;
    
    @media(max-width: 767px){
        padding: 0;
        margin: 10px;
    }

    &.wp-block-quote{
        &.is-style-large{
            padding: 20px 60px 20px;
            @media(max-width: 767px){
                padding: 35px;
                margin: 10px;

            }
            &:not(.is-style-plain){
                padding: 20px 60px 20px;
            }
        }
        
    }
    p{
        margin-bottom: 30px;
    }
    cite{
        text-decoration: underline;
    }
     &:before{
        position: absolute;
        left: 10px;
        top: 25px;
        content: "\f17f";
        font-family: "ovaicon";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        vertical-align: middle;
        font-size: 30px;
        color: var(--primary);
        @media(max-width: 767px){
            left: -20px;
            top: -5px;
            font-size: 15px;
        }
     }

    &.has-text-align-right{
        &:before{
            right: 0;
            left: auto;
            transform: rotate(180deg);
            width: 30px;
            height: 30px;
            text-align: right;
            position: absolute;

            @media(max-width: 767px){
                right: -20px;
                width: 15px;
                height: 15px;
            }
        }
    } 

    &.left{
        margin-right: 20px;
        text-align: right;
        margin-left: 0;
        width: 33%;
        float: left;    
    }
    
    &.right {
        margin-left: 20px;
        text-align: left;
        margin-right: 0;
        width: 33%;
        float: right;
    }
}



address {
    font-style: italic;
    margin: 0 0 24px;
}

cite, 
em, 
var, 
address, 
dfn {
    font-style: italic;
}

pre{
    border: 1px solid #d1d1d1;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.3125;
    margin: 1.75em 0  1.75em 0;
    max-width: 100%;
    overflow: auto;
    padding: 1.75em;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    
}

hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin: 0 0 1em;
}


.elementor-widget-text-editor p{
    line-height: inherit;
}

.btn,
.button{
    border-radius: $border-radius-btn-2;
    padding: $padding-btn-2;
    line-height: $line-height-form;

}


