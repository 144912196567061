.ova-team {
    display: block;
    position: relative;
}

// general template 
.item-team {  
    
    .info {
       a{
            &:hover {
                .name {
                    color: var(--primary);
                }
            }
       }  

        .name {
            line-height: 1.4;
            color: var(--heading);
            font-weight: 800;
            @include transaction( all, 0.3s, ease);
        }

        .job {
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 1.2;
            text-transform: uppercase;;
            letter-spacing: 1px;
            @include transaction( all, 0.3s, ease);
        }
    }
    
}

// template1
.item-team-1 {

    &:hover {
        .avatar {
            .decor-geometry {
                @include transaction( all, 0.5s, ease);
                opacity: 1;
                visibility: visible;
                left: 0;
            }
            .social-list{
                @include transaction( all, 0.5s, ease);
                bottom: 0;
                opacity: 1;
                visibility: visible;
            }
            img {
                transform: scale(1.05);
            }
        }
    }
    
    .avatar {
        position: relative;
        overflow: hidden;
        
        .decor-geometry {
            @include transaction( all, 0.5s, ease);
            position: absolute;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            top: 0;
            left: -30px;
            width: 135px;
            height: 165px;
            border-bottom-right-radius: 165px;
            background-color: var(--primary);
            i {
                position: absolute;
                font-size: 22px;
                top: 40px;
                left: 40px;
                color: #fff;
                line-height: 1;
                @media (max-width: 767px) {
                    font-size: 18px;
                    top: 20px;
                    left: 20px;
                }
            }
            @media (max-width: 767px) {
                width: 70px;
                height: 100px;
                opacity: 1;
                visibility: visible;
                left: 0;
            }
            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 133px;
                height: 142px;
                border-bottom-right-radius: 142px;
                background-color: var(--heading);
                @media (max-width: 767px) {
                    width: 68px;
                    height: 77px;
                }
            }
        }
        .trapezoid {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 60%;
            height: 50px;
            border-bottom: 50px solid #fff;
            border-right: 37px solid transparent;
            z-index: 1;
            @media (max-width: 420px) {
                width: 50%;
                border-right: 50px solid transparent;;
            }
        }

        img{
            height: 520px;
            object-fit: cover;
            width: 100%;
            display: block;
            @include transaction( all, 0.3s, ease);
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            @media (max-width: 767px) {
                height: 420px;
            }
        }

        .social-list {
            @include transaction( all, 0.5s, ease);
            list-style: none;
            margin: 0;
            padding: 10px 24px;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            background-color: var(--primary); 

            position: absolute;
            bottom: -30px;
            right: 0;
            opacity: 0;
            visibility: hidden;
            @media (max-width: 767px) {
                opacity: 1;
                visibility: visible;
                bottom: 0;
            }

            .social {
                display: flex;
                width: 13px;
                height: 13px;
                background-color: transparent;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 8px;

                i {
                    @include transaction( all, 0.3s, ease);
                    display: inline-flex;
                    font-size: 13px;
                    line-height: 1;
                    color: #fff;
                }

                &:hover {
                    @include transaction( all, 0.3s, ease);
                    background-color: var(--primary);
                    i {
                        @include transaction( all, 0.3s, ease);
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    
    .info {
        position: relative;
        display: inline-block;
        z-index: 2;
        margin: -22px 0 0 0;
        .name {
            margin: 4px 0 0 0;
            font-size: 20px;
        }
    }
    
}

// template2
.item-team-2 {

    &:hover {
        .avatar {
             img {
                transform: scale(1.05);
            }
            .social-list {
                @include transaction( all, 0.5s, ease);
                bottom: 0;
                opacity: 1;
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
        .info {
            .share-button {
                i {
                    transition: all 0.5s ease;
                    -webkit-transition-delay: 0.1s;
                    transition-delay: 0.1s;
                    -webkit-transform: scale(0.95) rotateY(360deg);
                    transform: scale(0.95) rotateY(360deg);
                }
            }
        }
    }

    .avatar {
        position: relative;
        overflow: hidden;

        img{
            height: 370px;
            object-fit: cover;
            width: 100%;
            display: block;
            @include transaction( all, 0.5s, ease);
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        .social-list {
            @include transaction( all, 0.5s, ease);
            list-style: none;
            margin: 0;
            padding: 24px 22px;
            display: block;
            background-color: var(--primary); 

            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;

            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);

            @media (max-width: 767px) {
                opacity: 1;
                visibility: visible;
                bottom: 0;
            }

            li:last-child {
                .social {
                    margin: 0;
                }
            }

            .social {
                display: flex;
                width: 16px;
                height: 16px;
                background-color: transparent;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 0 32px 0;

                i {
                    @include transaction( all, 0.3s, ease);
                    display: inline-flex;
                    font-size: 16px;
                    line-height: 1;
                    color: #fff;
                }

                &:hover {
                    @include transaction( all, 0.3s, ease);
                    background-color: var(--primary);
                    i {
                        @include transaction( all, 0.3s, ease);
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    
    .info {
        position: relative;
        padding: 20px 0;
        text-align: right;
        .share-button {
            transition: all 0.5s ease;
            position: absolute;
            left: 0;
            top: 0;
            width: 60px;
            height: 60px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: var(--primary);
            i {
                transition: all 0.5s ease;
                font-size: 19px;
                color: #fff;
                -webkit-transition-delay: 0.1s;
                transition-delay: 0.1s;
                -webkit-transform: scale(1) rotateY(0deg);
                transform: scale(1) rotateY(0deg);
            }
        }

        .name {
            margin: 0;
            font-size: 24px;
        }
    }
    
}

// template3
.item-team-3 {

    &:hover {
        .avatar {
            .social-list{
                @include transaction( all, 0.5s, ease);
                bottom: 0;
                opacity: 1;
                visibility: visible;
            }
            img {
                transform: scale(1.05);
            }
        }
    }

    .avatar {
        position: relative;
        overflow: hidden;

        img{
            height: 350px;
            object-fit: cover;
            width: 100%;
            display: block;
            @include transaction( all, 0.3s, ease);
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        .social-list {
            @include transaction( all, 0.5s, ease);
            list-style: none;
            margin: 0;
            padding: 10px 24px;
            display: inline-flex;
            justify-content: flex-end;
            background-color: var(--primary); 

            position: absolute;
            bottom: -30px;
            right: 0;
            opacity: 0;
            visibility: hidden;

            @media (max-width: 767px) {
                opacity: 1;
                visibility: visible;
                bottom: 0;
            }

            .social {
                display: flex;
                width: 13px;
                height: 13px;
                background-color: transparent;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 8px;

                i {
                    @include transaction( all, 0.3s, ease);
                    display: inline-flex;
                    font-size: 13px;
                    line-height: 1;
                    color: #fff;
                }

                &:hover {
                    @include transaction( all, 0.3s, ease);
                    background-color: var(--primary);
                    i {
                        @include transaction( all, 0.3s, ease);
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    
    .info {
        padding: 30px;
        background-color: #fff;
        .name {
            margin: 4px 0 0 0;
            font-size: 24px;
        }

        .job {
            color: var(--primary);
        }
    }
    
}

// template4
.item-team-4 {

     &:hover {
        .avatar {
            .social-list{
                @include transaction( all, 0.5s, ease);
                opacity: 1;
                visibility: visible;
                right: 120px;
                bottom: 40px;
            }
        }
        .info {
            .job {
                @include transaction( all, 0.3s, ease);
                border-left: 2px solid transparent;
            }
        }
    }

    .avatar {
        position: relative;
        overflow: hidden;

        img{
            height: 470px;
            object-fit: cover;
            width: calc(100% - 30px);
            display: block;
            @include transaction( all, 0.3s, ease);
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            @media (max-width: 420px) {
                width: 100%;
            }
        }

        .social-list {
            @include transaction( all, 0.5s, ease);
            list-style: none;
            margin: 0;
            padding: 10px 24px;
            display: inline-flex;
            justify-content: flex-end;
            background-color: #fff; 

            position: absolute;
            bottom: 40px;
            right: 0;
            opacity: 0;
            visibility: hidden;

            @media (max-width: 767px) {
                opacity: 1;
                visibility: visible;
                bottom: 0;
            }

            .social {
                display: flex;
                width: 13px;
                height: 13px;
                background-color: transparent;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 8px;

                i {
                    @include transaction( all, 0.3s, ease);
                    display: inline-flex;
                    font-size: 13px;
                    line-height: 1;
                    color: var(--heading);
                }

                &:hover {
                    @include transaction( all, 0.3s, ease);
                    i {
                        @include transaction( all, 0.3s, ease);
                        color: var(--primary);
                    }
                }
            }
        }
    }
    
    .info {
        position: relative;
        margin-top: -40px;
        padding: 30px 35px;
        width: calc(100% - 60px);
        margin-left: auto;
        background-color: var(--heading);
        @media (max-width: 420px) {
            padding: 30px 25px;
            width: calc(100% - 25px);
        }
        .name {
            margin: 0;
            font-size: 24px;
            color: #fff;
        }

        .job {
            position: absolute;
            bottom: 100%;
            right: 0;
            padding: 10px 18px;
            width: 120px;
            text-align: center;
            background-color: var(--primary);
            border-left: 2px solid #fff;
            color: #fff;
        }
    }
    
}

// template5
.item-team-5 {

    &:hover {
        .info {
            @include transaction( all, 0.5s, ease);
            margin: -60px 0 0 0;
            &:before {
                @include transaction( all, .5s, ease);
                bottom: calc(100% + 5px);
                opacity: 1;
            }
            @media (max-width: 767px) {
                margin: 0;
            }
            .description {
                @include transaction( all, 0.5s, ease);
                margin: 0;
            }
        }
        .social-list {
            @include transaction( all, 0.5s, ease);
            padding: 20px 0 0 0;
            visibility: visible;
            opacity: 1;
        }
    }

    .avatar {
        position: relative;

        img{
            width: 100%;
            height: 260px;
            object-fit: cover;
            display: block;
            @include transaction( all, 0.5s, ease);
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    .social-list {
        @include transaction( all, 0.5s, ease);
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        opacity: 0;
        visibility: hidden;

        @media (max-width: 767px) {
            opacity: 1;
            visibility: visible;
            padding: 20px 0 0 0;
        }

        li:last-child {
            .social {
                margin: 0;
            }
        }

        .social {
            display: flex;
            width: 40px;
            height: 40px;
            background-color: var(--heading);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 12px 0 0;

            i {
                @include transaction( all, 0.3s, ease);
                display: inline-flex;
                font-size: 16px;
                line-height: 1;
                color: #fff;
            }

            &:hover {
                @include transaction( all, 0.3s, ease);
                background-color: var(--primary);
                i {
                    @include transaction( all, 0.3s, ease);
                }
            }
        }
    }
    
    .info {
        @include transaction( all, .5s, ease);
        position: relative;
        z-index: 1;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
        padding: 30px;
        margin: 0;
        &:before {
            @include transaction( all, .5s, ease);
            position: absolute;
            opacity: 0;
            content: "";
            bottom: 100%;
            left: 0;
            height: 30px;
            width: 100%;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            background-color: var(--primary);
        }
        &:after {
            position: absolute;
            content: "";
            bottom: 100%;
            left: 0;
            height: 20px;
            width: 100%;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            background-color: #fff;
        }

        .name {
            position: relative;
            z-index: 1;
            margin: 4px 0 18px 0;
            font-size: 20px;
        }

        .job {
            color: var(--primary);
        }
        .description {
            @include transaction( all, 0.5s, ease);
            margin: 0 0 -40px 0;
            @media (max-width: 767px) {
                margin: 0;
            }
        }
    }
    
}