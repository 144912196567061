.ova-image-slider{

	&.owl-carousel{
		position: relative;
		img{
			width: 100%;
			max-width: 122px;
			object-fit: cover;
			opacity: 0.2;
    		margin: 0 auto;
		}

		.owl-item {
			padding: 30px;
			&:hover{
				background: var(--primary);
				img{
					opacity: 0.6;
				}
			}
		}
	
		.owl-dots{
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 30px 5px;
			position: relative;
			bottom: 4px;

			button{
				width: 6px;
				height: 6px;
				background-color: #dcdee2;
				border-radius: 100%;
				margin: 0px 5px;
				&.active{
					background-color: var(--primary);
					width: 12px;
					border-radius: 5px;
				}
			}
		}
		
		.owl-nav{	
			position: absolute;
			bottom: 100%;
			right: 0%;

			button{
				width: 50px;
				height: 50px;
				border-radius: 100px;
				padding: 0px;
				border-color: transparent;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				box-shadow: 0px 4px 15px 0px #661FFE40;
				background-color: #fff;

				i{
					font-size: 11px;
				}

				&:hover{
					background-color: var(--primary);
					i{
						color: #ffffff;
					}
				}

				&.owl-prev{
					margin-right: 10px;
				}
			}

		}
	}
	
}