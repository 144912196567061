/*Block Button*/
.wp-block-button{
	margin: 10px 0px;
	a{
		text-transform: uppercase;
		font-weight: 600;	
	}
	
	&.alignleft{
		margin-right: 20px;
	}

	&.is-style-outline{
		.wp-block-button__link{
			border-style: dashed;
		}
	}
	&.is-style-squared{
		.wp-block-button__link{
			border-radius: 5px;	
		}
		
	}
	&.alignright{
		margin-left: 20px;
	}
}

/*Block Image*/
.wp-block-image,
figure{
	figcaption{
		text-align: left;
	    font-style: italic;
	    border-bottom: 2px solid #e6e6e6;
	    padding: 0.5407911001em 0;
	    font-size: 0.875em;
	    color: var(--text);
	    opacity: 0.8;
	    padding-top: 0;
	    margin-top: 5px;
	}
}
.wp-block-image {
	margin: 0 0 2em;
}


/*Block Covern*/
.wp-block-cover{
	margin-bottom: 30px;
	&.has-background-dim{
		color: #fff;
	}
}

/*Block Gallery*/
.wp-block-gallery{
	margin-bottom: 20px;
	padding-top: 20px;
	&:not(.has-nested-images).alignleft{
		margin-right: 30px;
		
	}
	&:not(.has-nested-images){
		margin-bottom: 30px;
	}
}


/*Block Category*/
.category-block{

}

/*Block Column*/
.wp-block-columns{
	blockquote{
		padding: 20px 10px 20px 50px;
		&:before{
			left: 10px;
			top: 5px;
		}
	}
}


/*Block Group*/
.wp-block-group{
	&.has-background{
		margin-bottom: 30px;
		padding: 30px;
	}
}

/*Block media*/
.wp-block-media-text{
	margin-bottom: 30px;
}

/*Block Archive Widget*/
.wp-block-archives{

	&.wp-block-archives-list{
		list-style-type: none;
		margin: 0 0 30px 0;
		padding: 0;
		li{
			margin: 5px 0px;
			a{
				position: relative;
				&:before{
				    content: "\f161";
				    font-family: "ovaicon";
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				    display: inline-block;
				    font-style: normal;
				    font-variant: normal;
				    font-weight: normal;
				    vertical-align: middle;
				    margin-right: 0.6180469716em;
				    display: block;
				    float: left;
				    color: var(--text);
				    opacity: 0.5;
				    width: 1em;
				    font-size: 0.9em;
				    line-height: inherit;
				}
			}
		}
	}
}


/*Block Calendar*/
.wp-block-calendar{
	margin-bottom: 30px;
}

/*Bock categories*/
.wp-block-categories{
	list-style-type: none;
	margin: 0 0 30px 0;
	padding: 0;
	li{
		margin: 5px 0px;
		a{
			position: relative;
			&:before{
			    content: "\f161";
			    font-family: "ovaicon";
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    display: inline-block;
			    font-style: normal;
			    font-variant: normal;
			    font-weight: normal;
			    vertical-align: middle;
			    margin-right: 0.6180469716em;
			    display: block;
			    float: left;
			    color: var(--text);
			    opacity: 0.5;
			    width: 1em;
			    font-size: 0.9em;
			    line-height: inherit;
			}
		}
	}
}

/*Block latest comment*/
.wp-block-latest-comments{
	padding-left: 0;
	&.has-avatars{
		li{
			&:before{
				display: none;
			}	
		}
	}
	li{
		margin-bottom: 2em;
		line-height: 1.5em;
		&:before{
			content: "\f151";
		    font-family: "ovaicon";
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    display: inline-block;
		    font-style: normal;
		    font-variant: normal;
		    font-weight: normal;
		    vertical-align: middle;
		    margin-right: 0.6180469716em;
		    display: block;
		    float: left;
		    opacity: 0.5;
		    width: 1em;
		    font-size: 0.9em;
		    line-height: inherit;
		}
		.wp-block-latest-comments__comment-author{
			text-decoration: underline;
		}
		a{
			color: var(--primary);
		}
		time{
			padding-top: 10px;
		}
	}
}

/*Block search*/
.wp-block-search{
	margin-bottom: 30px;
	.wp-block-search__label{
		margin-bottom: 10px;
		display: block;
	}
	&:before{
		display: none!important;
	}
	.wp-block-search__input{
		border-color: $border-field-color;
		background-color: $background-field;
		padding-right: 20px!important;
		padding-left: 20px!important;
	}
	.wp-block-search__button{
		padding: $padding-btn;
		background-color: var(--primary);
		border-color: var(--primary);
		color: #fff;
		font-weight: 600;
		margin-left: 5px;
		&:hover{
			background-color: transparent;
			color: var(--primary);
		}
	}
}

/*Recent Post*/
.wp-block-latest-posts{
	li{
		a{
			position: relative;
			&:before{
			    content: "\f163";
			    font-family: "ovaicon";
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    display: inline-block;
			    font-style: normal;
			    font-variant: normal;
			    font-weight: normal;
			    vertical-align: middle;
			    margin-right: 0.6180469716em;
			    display: block;
			    float: left;
			    color: var(--text);
			    opacity: 0.5;
			    width: 1em;
			    font-size: 0.9em;
			    line-height: inherit;

			}
		}
	}
	&.has-dates{
		li{
			margin-bottom: 15px;
		}
		time{
			padding-left: 20px;
		}
	}
}

/*Tags*/
.wp-block-tag-cloud{
	clear: both;
	position: relative;
	&:after{
		clear: both;
		content: "";
		display: inline-block;
		width: 100%;
	}
	a{
	    float: left;
	    margin: 0 10px 10px 0;
	    display: block;
	    font-size: 0.9em !important;
	    @include tag();	
	    
	}
}

/*RSS*/
.wp-block-rss{
	margin: 0;
	padding: 0;
	list-style-type: none;
	li{
		margin-bottom: 5px;
		a{

			&:before{
				 content: "\f197";
			    font-family: "ovaicon";
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    display: inline-block;
			    font-style: normal;
			    font-variant: normal;
			    font-weight: normal;
			    vertical-align: middle;
			    margin-right: 0.6180469716em;
			    display: block;
			    float: left;
			    color: var(--text);
			    opacity: 0.5;
			    width: 1em;
			    font-size: 0.9em;
			    line-height: inherit;
			}
		}
	}
}

.widget_block{
	.wp-block-group__inner-container > h2{
		font-size: 1.3195em;
		padding-bottom: 15px;
    	margin-bottom: 15px;
	}
}
