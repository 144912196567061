.ova-image{
	position: relative;
    height: 100%;
    display: flex;
    @media (max-width: 1100px) {
    	max-height: auto; 
    }
	.text-content{
		font-size: 18px;
		font-weight: 800;
		color: #ffffff;
		background-color: var(--primary);
	    margin: 0;
    	display: inline-flex;
    	letter-spacing: 2px;
    	padding: 15px 55px;
    	text-transform: uppercase;
        position: absolute;
        z-index: 1;
	    top: 50%;
	    transform-origin: left center;
	    transform: rotate(90deg) translate(-60%, -50%);

	    @media( max-width: 767px){
    		padding: 5px 15px;
    	}
	}

	img{
		position: relative;
		z-index: 0;
		width: 100%;
		object-fit:cover;
		height: 100% !important;
		@media( max-width: 768px){
    		height: 400px;
    	}
	}

	&.version_1{
		position: relative;
		display: flex;
		.line-right{
			content: '';
			display: block;
			width: 20px;
			height: calc(100% - 120px);
			position: absolute;
			left: 100%;
			bottom: 0;
			background-color: var(--primary);
		}
		.line-top{
			content: '';
			display: block;
			width: 69%;
			height: 20px;
			position: absolute;
			bottom: 100%;
			left: 20px;
			background-color: var(--primary);
			@media(max-width: 1024px){
				width: 60%;
			}
			&:after{
				content: '';
				width: 12px;
			    height: calc(100%);
			    display: block;
			    position: absolute;
		        z-index: 2;
			    top: 0;
			    left: calc(100%);
			    background: var(--primary);
				clip-path: polygon(0 0, 0% 100%, 100% 100%);
			}
		}

		.shape_top_right{
			width: 20%;
		    height: 30%;
		    display: block;
		    position: absolute;
	        z-index: 2;
		    top: -1px;
		    right: -1px;
		    background: #ffffff;
			clip-path: polygon(0 0, 100% 0, 100% 100%);
		}
	}

	&.version_2{
	    margin-left: 100px;
	    position: relative;

	    @media(max-width: 768px){
	    	margin-left: 0px;
	    }	    

		.text-content{
		    top: 0;
		    padding: 0;
	        left: -50px;
		    transform-origin: 0 0;
		    transform: rotate(90deg);
		    width: max-content;
		    font-size: 60px;
		    letter-spacing: 24px;
		    background-color: transparent;
		    line-height: 1;
		}

		.text-content-2{
		    left: 100%;
		    top: calc(100% - 260px);
		    right: 0px;
		    transform-origin: 0 0;
		    transform: rotate(90deg);
		    width: max-content;
		    font-size: 60px;
	        font-weight: 800;
		    letter-spacing: 24px;
		    background-color: transparent;
		    line-height: 1;
		    position: absolute;
		    z-index: 3;
		    text-transform: uppercase;
		    margin: -40px 0px 0px -30px;
		    color: #ffffff66;
	      	-webkit-text-fill-color: transparent;
  			-webkit-text-stroke: 1px;
		}

		&:before{
			display: block;
			width: 100%;
			height: 100%;
			background-color: var(--primary);
			content: '';
			position: absolute;
			z-index: 0;
			top: 20px;
			left: -20px;
		}
	}
}

.elementor-widget-auntri_elementor_ova_image{
	height: 100%;
	.elementor-widget-container{
		height: 100%;
	}
}

//customize hover image 
.ova-hover-filter-image{
	&:hover{
		img{
			-webkit-filter: brightness(0) invert(1);
  			filter: brightness(0) invert(1);
		}
	}
	
}