.ova-title{
	position: relative;
	
	.sub-title{
		font-size: 16px;
		font-weight: 600;
		margin: 0;
		font-family: var(--primary-font);
		color: var(--text);
		line-height: 1.2;
		text-transform: capitalize;
    	margin-bottom: 7px;
    	letter-spacing: 1px;
	}

	.box-title{
		position: relative;
		.line-top{
		    width: 30px;
	  		height: 2px;
			background-color: var(--primary);
			display: block;	
			position: absolute;
			top: 0px;
			left: 50%;
			transform: translate(-50%, -50%);
	    }
	}

	.title{
		margin: 0;
		margin-bottom: 30px;
		font-size: 50px;
		font-weight: 800;
		line-height: 1.21;
		white-space: pre-line;
		@media (max-width: 767px) {
			font-size: 40px;
			white-space: normal;
		}

		a{
			@include transaction( transform, 0.3s, ease);
			color: var(--heading);
			
			&:hover{
				@include transaction( transform, 0.3s, ease);
				color: var(--primary);
			}
		}

		span{
			position: relative;
			display: inline-flex;
			z-index: 1;
			&:before{
				content: '';
			    width: 112%;
			    height: 130%;
			    display: block;
			    position: absolute;
			    z-index: 0;
			    top: 37%;
			    left: 56%;
			    transform: skew(10deg, -5deg) translate(-50%, -50%);
			    border: 2px solid var(--primary);
			    border-radius: 50%;
			    border-left-width: 6px;
			    border-right-width: 6px;
			    border-top-width: 10px;
			    border-bottom-width: 8px;
			}

			&:after{
				content: '';
				width: 104%;
				height: 120%;
				display: block;
				position: absolute;
				z-index: 0;
				top: 37%;
				left: 52%;
				transform: skew(10deg, -4deg) translate(-50%, -50%);
				border: 2px solid var(--primary);
				border-radius: 50%;
				border-bottom-right-radius: 47%;
				border-top-right-radius: 45%;
				border-left-width: 0px;
				border-right-width: 6px;
				border-top-width: 4px;
				border-bottom-width: 4px;
			}
		}

	}

	.description{
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
		color: var(--text);
	}

}