.ova-contact-info{
	display: flex;
	align-items: center;

    .icon{
    	-webkit-transition: all .5s ease;
        transition: all .5s ease;
	    border-radius: 50%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 16px;
		color: var(--primary);
		margin-right: 10px;
		i {
			display: inline-flex;
		}
	}

	.contact{
        line-height: 1;
		.label{
			font-size: 15px;
			padding: 0;
			font-weight: 500;
			line-height: 1.2;
			margin: 0 0 10px 0;
		}

		.info{
			list-style-type: none;
			padding: 0;
			margin: 0;

			.item{
				color: var(--text);
				a{
					display: inline-block;
					font-size: 12px;
					font-weight: 500;
					line-height: 1.4;
					color: var(--text);
					-webkit-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
					position: relative;

					&:before{
						position: absolute;
					    content: '';
					    width: 0%;
					    height: 2px;
					    left: 0px;
					    bottom: 0px;
					    transition: all 500ms ease;
					    background-color: var(--primary);
					}

					&:hover{
						color: var(--primary);
						&:before{
							width: 100%;
						}
					}
				}
			}
		}

	}
}

/* ova-background-icon-primary */
.ova-background-icon-primary{
    .ova-contact-info {
    	.icon {
    		background-color: var(--primary);
    	}
    }
}