.ova-icon-box {
	@include transaction( all, 0.3s, ease);
	z-index: 1;
    text-align: left;

    &:after {
    	@include transaction( all, 0.5s, ease);
    	position: absolute;
	    content: "";
	    top: 0;
	    left: 0;
	    right: 0;
	    height: 100%;
	    width: 101%;
	    background-color: transparent;
	    -webkit-transform-origin: top;
	    transform-origin: top;
	    -webkit-transform-style: preserve-3d;
	    transform-style: preserve-3d;
	    -webkit-transform: scaleY(0);
	    transform: scaleY(0);
	}

    &:hover {
    	&:after {
			@include transaction( all, 0.5s, ease);
			-webkit-transform: scaleY(1);
		    transform: scaleY(1);
    	}
       	.icon {
       		i {
       			@include transaction( all, 0.3s, ease);
       		}
       	}
       	.title {
       		@include transaction( all, 0.3s, ease);
       	}
       	.description {
       		@include transaction( all, 0.3s, ease);
       	}
    }

    .text-number {
    	@include transaction( all, 0.5s, ease);
    	color: #eff1f5;
    	font-size: 50px;
    	font-weight: 300;
	    display: inline-flex;
	    line-height: 1;
    }

	.icon {
		@include transaction( all, 0.3s, ease);
		display: inline-flex;
		align-items: flex-end;
		position: relative;
		z-index: 2;
		svg{
			display: inline-flex;
			width: 68px;
			height: auto;
		    fill: var(--primary);
		    margin-left: -30px; 
		    path {
		    	fill: var(--primary);
		    }
		}
        i {
        	@include transaction( all, 0.3s, ease);
        	display: inline-flex;
         	font-size: 64px;
         	color: var(--primary); 
        }
	}

	.title {
		@include transaction( all, 0.3s, ease);
		margin: 0;
		margin-bottom: 5px; 
        font-weight: 800;
		font-size: 24px;
		line-height: 1.2;
		color: var(--heading);
		position: relative;
		z-index: 2;
	}

	.description {
		@include transaction( all, 0.3s, ease);
		margin: 0;
        font-weight: 500;
		font-size: 16px;
		line-height: 1.5;
		position: relative;
		z-index: 2;
	}
 
 	// template 1
 	&.ova-icon-box-template1 {
 		position: relative;
 		overflow: hidden;
 		padding: 40px;
 		padding-bottom: 30px;
 		background-color: #fff;
 		&:hover{
			.title {
				color: #fff;
			}
			.text-number{
				@include transaction( all, 0.5s, ease);
			}
		}
 		.icon-box{
 			position: relative;
		    display: flex;
			justify-content: space-between;
		    align-items: start;
		    margin-bottom: 20px;
    		z-index: 1;
 		}

 		&:after {
		    background-color: var(--heading);
		}
 	}
	
	// template2
	&.ova-icon-box-template2 {
	    padding: 0px 100px 80px 40px;
	    margin-bottom: 60px;
		text-align: left;
		background-color: #0e1a2f;
    	position: relative;
	    min-height: 400px;

	    .box-icon-v2{
			width: 102px;
		    height: 120px;
		    background: var(--primary);
		    border-radius: 0 0 100px 100px;
		    display: flex;
		    justify-content: center;
		    align-items: end;
		    position: relative;
		    z-index: 10;
		    margin-bottom: 17px;
		    .icon{
		    	i{
		    		color: #ffffff;
		    	}
		    }
	    }

		@media (max-width: 500px) {
			padding: 40px 30px;
		}

		&:after {
        	content: unset;	
        }

        &:hover {
        	background-color: var(--primary);
        	.mask {
        		@include transaction( all, 0.3s, ease);
        		opacity: 0;
        	}

			.mask-second{
        		@include transaction( all, 0.6s, ease);
		  		opacity: 1;
		  		transform: scale(1.15) rotate(0deg);
		  		-webkit-mask-image: url(./assets/img/elementor/shape-icon-box-version-2.png);
			  	mask-image: url(./assets/img/elementor/shape-icon-box-version-2.png);
			  	-webkit-mask-size: cover;
			  	mask-size: cover;
			  	-webkit-mask-repeat: no-repeat;
			  	mask-repeat: no-repeat;
			  	background-image: linear-gradient(#fc811b 10%, #ff4040 72%, #fc811b 100%);
			}

			.box-icon-v2{
				background-color: #ffffff;
				i{
					color: var(--primary);
				}
			}

        	.icon {

        		i {
        			color: var(--primary);
        		}

                &:after {
                    -webkit-transform: scaleY(1);
        		    transform: scaleY(1);
                }
        	}

        	.title {
        		@include transaction( all, 0.3s, ease);
        	}

        	.description {
        		@include transaction( all, 0.3s, ease);
        	}
        }

        &.ova-icon-box-active {
         	&:after {
	    		@include transaction( all, 0.5s, ease);
	    		-webkit-transform: scaleY(1);
	    		transform: scaleY(1);
	    	}
        	background-color: var(--primary);
        	.mask {
        		@include transaction( all, 0.3s, ease);
        		opacity: 0;
        	}

			.mask-second{
        		@include transaction( all, 0.6s, ease);
		  		opacity: 1;
		  		transform: scale(1.15) rotate(0deg);
		  		-webkit-mask-image: url(./assets/img/elementor/shape-icon-box-version-2.png);
			  	mask-image: url(./assets/img/elementor/shape-icon-box-version-2.png);
			  	-webkit-mask-size: cover;
			  	mask-size: cover;
			  	-webkit-mask-repeat: no-repeat;
			  	mask-repeat: no-repeat;
			  	background-image:  linear-gradient(#fc811b 10%, #ff4040 72%, #fc811b 100%);
			}

			.box-icon-v2{
				background-color: #ffffff;

			}
        	.icon {
                &:after {
                    -webkit-transform: scaleY(1);
        		    transform: scaleY(1);
                }
                i {
                 	color: var(--primary);
                }
        	}
        	.title {
        		color: #fff;
        	}
        	.description {
        		color: #ffffff;
        	}
        }

        .mask {
        	@include transaction( all, 0.3s, ease);
        	position: absolute;
        	content: "";
        	width: 100%;
        	height: 100%;
        	left: 0;
        	top: 0;
        	opacity: 0.4;
    		background-position: center;
	        background-size: cover;
	        background-repeat: no-repeat;
	        z-index: 1;
        }

            .mask-box{
    		    width: 100%;
			    height: 100%;
			    overflow: hidden;
			    position: absolute;
			    z-index: 0;
			    top: 0;
			    left: 0;
        	}

        .mask-second{
        	
        	transform: scale(0.5) rotate(90deg);

        	-webkit-mask-image: url(./assets/img/elementor/shape-icon-box-version-2.png);
		  	mask-image: url(./assets/img/elementor/shape-icon-box-version-2.png);
		  	-webkit-mask-size: 100%;
		  	mask-size: 100%;
		  	-webkit-mask-repeat: no-repeat;
		  	mask-repeat: no-repeat;
		  	@include transaction( all, 0.6s, ease);

		  	position: absolute;
        	content: "";
        	width: 100%;
        	height: 100%;
        	left: 0;
        	top: 0;
    		background-position: center;
	        background-size: cover;
	        background-repeat: no-repeat;
	        z-index: 1;
        }

		.icon {
		    background-color: transparent;
		    margin: 0 0 30px 0;
		    svg{
				margin: 0;
			}
		    i {
		    	margin: 0;
		    }
		}

		.title {
			color: #ffffff;
		    margin-bottom: 29px;
		}

		.description {
			font-size: 16px; 
			color: #ffffffcc;
		}

		.btn-readmore{
			@include transaction( all, 0.6s, ease);
		    position: absolute;
		    overflow: hidden;
		    top: 100%;
		    right: 0;
		    background: #fff;
		    border-radius: 0; 
		    font-size: 12px;
		    font-weight: 800;
		    text-transform: uppercase;
		    color: var(--heading);
		    letter-spacing: 1px;
	        padding: 21px 50px 20px 50px;
		    span{
		    	position: relative;
		    	z-index: 1;
		    }
		    &:before{
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: calc(100% + 1px);
                -webkit-transform-origin: top;
                transform-origin: top;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                z-index: 0;
               	background-color: var(--primary);
            }

            &:hover{
            	@include transaction( all, 0.6s, ease);
                &:before{
                    transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }
		}
	}

	&.ova-icon-box-template3,
	&.ova-icon-box-template4{
		
		.icon{
			background-color: var(--primary);
			padding: 21px;
			border-radius: 50%;
		    position: relative;

			i{
				color: #fff;
			}

			.text-number{
				position: absolute;
				top: 50%;
				right: 0;
    			transform: translate(35px, -50%);
    			font-size: 16px;
    			font-weight: 800;
    			color: var(--heading);
    			background-color: #fff;
    			border-radius: 50%;
    			padding: 13px 11px;
			}
		}

		.title{
			margin-bottom: 17px;
		}

		.description{
			color: rgb(149, 156, 169);
		}
	}

	&.ova-icon-box-template3{
		position: relative;
		.icon{
			margin-bottom: 25px;
			.text-number{
				border: 4px solid var(--primary);
			}
		}

		.box-title-desc{
			padding: 30px;
			background-color: #ffffff;
		    z-index: 1;
    		position: relative;

    		&:after{
    			content: '';
    			display: block;
    			position: absolute;
    			width: 50px;
    			height: 10px;
    			bottom: 100%;
    			left: 30px;
    			background-color: #ffffff;
    			clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    		}
		}

		.btn-readmore{
			@include transaction( all, 0.3s, ease);
			position: absolute;
			top: 80%;
			left: 0;
			width: 100%;
			height: auto;
		    text-transform: uppercase;
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    background: var(--primary);
		    border: unset;
		    border-radius: unset;
		    font-size: 14px;
		    color: #ffffff;
		    padding: 6px 30px;
		   
		    @media(max-width: 767px){
		    	top:100%;
		    }

		    i{
		    	font-size: 16px;
		    	display: inline-flex;
		    }
		}

		&:hover{

			.btn-readmore{
				top: 100%;
				@include transaction( all, 0.3s, ease);
			}

			&:after{
				background-color: unset;
			}
			.title{
				color: inherit;
			}
			.description{
				color: #959ca9;
			}
		}
	}

	&.ova-icon-box-template4{
		background-color: #101c30;
		padding: 30px;
		padding-top: 0;
		margin-top: 65px;
		border: 2px solid transparent;
		.box-icon-v4{
			margin-bottom: 25px;
		    display: inline-flex;
    		border: 1px solid #ffffff33;
    		padding: 10px;
    		border-radius: 50%;
		    margin-top: -65px;
    		.icon{
    			background-color: #ffffff;
    			i{
    				color: var(--heading);
    			}
    		}
    	}

    	.title{
			color: var(--primary);
			margin-bottom: 17px;
		}
		&:hover{
			border-color: var(--primary);

			.box-icon-v4{
				.icon{
					background-color: var(--primary);
				}
			}
			&:after{
				background-color: unset;
			}
			.title{
				color: var(--primary);
			}
			.description{
				color: #959ca9;
			}
		}
	}

	&.ova-icon-box-template5{
		background-color: #fff;
		padding: 40px 120px 40px 40px; 
		position: relative;
		overflow: hidden;
		box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
		margin-bottom: 0px;
		.title{
			font-size: 22px;
			margin-bottom: 12px;
		}
		.description{
		    line-height: inherit;
		}

		.text-number{
			position: absolute;
			z-index: 1;
			top: 48px;
			right: 30px;
		}
		&:hover{
			&:after{
				-webkit-transform: scaleY(0);
				transform: scaleY(0);
			}
		}
	}

	&.ova-icon-box-template6{
	    display: flex;
    	justify-content: start;
    	align-items: start;
    	border-bottom: 1px solid var(--light);
    	padding-bottom: 29px;
    	padding-top: 29px;

    	.icon{
    		padding-right: 50px;
    		position: relative;
    		i{
    			position: relative;
    			z-index: 1;
    		}
    		&:after{
    			content: '';
    			display: block;
    			width: 44px;
    			height: 44px;
    			background-color: var(--heading);
    			position: absolute;
    			z-index: 0;
    			bottom: 0;
    			left: 40px;
    		}
    	}

    	.box-info{
		    display: flex;
		    flex-flow: column;
		    flex-direction: column-reverse;
    	}

    	.title{
    		margin: 0;
    	}

    	&:after{
    		content: unset;
    	}

    	&:hover{
    		background-color: rgb(255, 255, 255);
  			box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.15);
  			padding-left: 40px;
    		.title{
    			color: initial;
    		}
    		.description{
    			color: inherit;
    		}
    	}
	}

	&:hover {
		.title,
		.description{
			color: #fff;
		}
    }

}


.template5-box{
	.template5-line-bot{
		width: calc(100% - 40px);
	    height: 10px;
	    display: block;
	    margin-left: 20px;
	    background-color: var(--primary);
	    position: relative;
	    &:before{
	    	content: '';
	    	display: block;
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	width: 100%;
	    	height: 10px;
	    	z-index: 1;
	    	background-color: var(--heading);
			transition: all 0.5s ease;
		    -moz-transition: all 0.5s ease;
		    -webkit-transition: all 0.5s ease;
		    -o-transition: all 0.5s ease;
		    -webkit-transform: scaleX(0);
		    transform: scaleX(0);
	    }
	}

	&:hover{
		.ova-icon-box-template5{
			&:after{
				transition: all 0.5s ease;
			    -moz-transition: all 0.5s ease;
			    -webkit-transition: all 0.5s ease;
			    -o-transition: all 0.5s ease;
			    -webkit-transform: scaleY(1);
			    transform: scaleY(1);
				background-color: var(--primary);
			}
		}

		.template5-line-bot{
			&:before{
				transition: all 0.5s ease;
			    -moz-transition: all 0.5s ease;
			    -webkit-transition: all 0.5s ease;
			    -o-transition: all 0.5s ease;
			    -webkit-transform: scaleX(1);
			    transform: scaleX(1);
			}
		}
	}
}