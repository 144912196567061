.ova-geometry1 {
	overflow: hidden;
	.geometry1 { 
		content: ""; 
		position: absolute;
		top: 0;
		left: 0;
		width: 187px;
		height: 254px;
		opacity: 1;
		background-color: var(--primary);
		clip-path: polygon(0 0, 0% 100%, 100% 0);
		transform-origin: top left;
		@media (max-height: 767px) {
			width: 133px;
			height: 200px;
		}
	}
	.geometry2 {
		content: ""; 
		position: absolute;
		bottom: 0;
		right: -5%;
		opacity: 1;
		width: 60%;
		height: 90%;
		background-color: var(--background);
		clip-path: polygon(50% 0, 0% 100%, 100% 100%);
		@media (max-height: 767px) {
			width: 40%;
			height: 70%;
		}
	}
}

.ova-geometry2 {
	.geometry3 { 
		content: ""; 
		position: absolute;
		top: 60px;
		left: 0;
		width: 200px;
		height: 270px;
		opacity: 1;
		background-color: var(--background);
		clip-path: polygon(0 0, 0% 100%, 100% 50%);
		transform-origin: top left;
	}
}

.ova-geometry3 {
	.geometry4 { 
		content: ""; 
		position: absolute;
		top: 0;
		left: 100px;
		
		opacity: 1;
		display: flex;
		.item{
			width: 70px;
			height: 40px;
			background-color: var(--heading);
			clip-path: polygon(0 100%, 0 0, 100% 0);
		}
	}

	.geometry5 { 
		content: ""; 
		position: absolute;
		bottom: 0;
		left: 40%;
		opacity: 1;
		display: flex;
		.item{
			width: 70px;
			height: 40px;
			background-color: var(--heading);
			clip-path: polygon(0 100%, 0 0, 100% 100%);
		}
	}
}