.ova-blog{
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:30px;

    &.ova-column_2{
        grid-template-columns: 1fr 1fr;
        .item{
            .img{
                img{
                    height: auto;
                }
            }
        }
    }

    &.ova-column_4{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 1200px) {
        &.ova-column_2{
            grid-template-columns: 1fr 1fr;
        }
        &.ova-column_3{
            grid-template-columns: 1fr 1fr 1fr;
        }
        &.ova-column_4{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media (max-width: 1024px) {
        &.ova-column_2,
        &.ova-column_3,
        &.ova-column_4{
            grid-template-columns: 1fr 1fr;
        }    
    }

    @media (max-width: 600px) {
        &.ova-column_2,
        &.ova-column_3,
        &.ova-column_4{
            grid-template-columns: 1fr;
        }    
    }

    .item{
        &:hover{
            .media{
                a{
                    img{
                        @include transaction( all, .7s, ease);
                        transform: scale(1.12);
                    }
                }
            }
            .content{
                .post-meta{
                    .post-date{
                        &:before{
                            @include transaction( all, 0.5s, ease);
                            -webkit-transform: scaleY(1);
                            transform: scaleY(1);
                        }
                    }  
                }
            }
        }

        // Video, Media
        .media{
            position: relative;

            .box-img{
                position: relative;
                overflow: hidden;
            }
            a{  
                img{
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    @include transaction( all, .7s, ease);
                }
            }  
        }
        .post-date{
            position: relative;
            background-color: var(--primary);
            color: #fff;
            font-size: 16px;
            line-height: 1;
            padding: 13px 20px;
            @include transaction( all, 0.3s, ease);
            span{
                line-height: 1;
                position: relative;
                z-index: 1;
            }
        }
        .content{
            position: relative;
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
            text-align: center;
            margin: -30px 15px 0 15px;
            @media (max-width: 400px){
                padding-left: 20px;
                padding-right: 20px;
            }

            // Meta
            .post-meta{
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: inline-flex;
                margin-bottom: 18px;

                @media( max-width: 768px ){
                    margin-bottom: 0px;
                }
                .item-meta{
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 1;
                    margin: 0px 10px;
                    .left{
                        margin-right: 3px;
                        color: var(--primary);
                        i{
                            font-size: 14px;
                        }
                    }
                    .right{
                        a, .by{
                            font-size: 14px;
                            color: var(--text);
                            font-weight: 600; 
                        }
                        a{
                            text-transform: capitalize;
                            &:hover{
                                color: var(--primary);
                            }
                        }
                    }
                }

                .item-space{
                    line-height: 1;
                }
            }
          
            .post-title{
                margin: 0px 0 10px 0;
                a{
                    @include transaction( all, 0.3s, ease);
                    font-size: 20px;
                    font-weight: 800;
                    color: var(--heading);
                    &:hover{
                        color: var(--primary);
                        @include transaction( all, 0.3s, ease);
                    }   
                }
            }
            
            .read-more{
                text-align: left;
                @include transaction( all, 0.3s, ease);
                color: var(--text);
                display: inline-flex;
                justify-content: center;
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                align-items: center;
                gap:5px;
                letter-spacing: 1px;
                i{
                    display: flex;
                }
                &:hover{
                    color: var(--primary);
                    @include transaction( all, 0.3s, ease);
                }   
            }
        }
    }

    // version 1
    &.version_1 {
        @media (max-width: 1024px) {
           row-gap: 40px;
        }
        .item{
            &:hover {
                .content {
                    &:after {
                        @include transaction( all, 0.7s, ease);
                        width: calc(100% - 20px)
                    }
                }
            }
            .media{
                a{
                    img{
                        height: 282px;
                        @media (max-width: 1024px) {
                            height: auto;
                        }
                    }
                }
            }
            .post-date{
                position: absolute;
                top: -10px;
                left: 20px;
                font-size: 11px;
                font-weight: bold;
                &:before, &:after{
                    position: absolute;
                    content: "";
                    top: 0;
                    height: 10px;
                    width: 10px;
                    background-color: var(--primary);
                }
                &:before {
                    left: -9px;
                    clip-path: polygon(0 100%, 100% 100%, 100% 0);
                }
                &:after {
                    right: -9px;
                    clip-path: polygon(0 100%, 100% 100%, 0 0);
                }
            }
            .content{
                margin: 0;
                padding: 35px 50px 25px 50px;
                @media (max-width: 430px) {
                    padding: 35px 25px 25px 25px;
                }
                &:after {
                    @include transaction( all, 0.7s, ease);
                    position: absolute;
                    content: "";
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: var(--primary);
                    width: 0;
                    height: 10px;
                }
                .post-meta{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    width: max-content;
                    background-color: var(--background);
                    .item-meta{
                        .right{
                            a, span{
                                font-size: 12px;
                                font-weight: 500;
                            }
                            .by {
                                display: inline-block;
                                margin-right: 2px; 
                            }
                        }
                        .left {
                            i{
                                font-size: 12px;
                            }
                        }
                        span{
                            display: flex;
                        }
                        &.wp-author{
                            margin-left: 40px;
                            
                            @media( max-width: 768px ){
                                margin-left: 15px;
                            }
                        }
                        &.post-comment{
                            margin-right: 40px;

                            @media( max-width: 768px ){
                                margin-right: 15px;
                            }
                        }
                    }
                    .item-space{
                        line-height: 1;
                    }
                    li{
                        padding: 12px 0px;
                    }
                }
                .read-more{
                    position: relative;
                    line-height: 1.4;
                    &:after{
                        content: '';
                        width: 100%;
                        height: 2px;
                        display: block;
                        background-color: var(--text);
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        left: 0;
                    }
                    &:before{
                        @include transaction( all, 0.4s, ease);
                        content: '';
                        width: 0px;
                        height: 2px;
                        display: block;
                        background-color: var(--primary);
                        position: absolute;
                        z-index: 2;
                        bottom: 0;
                        left: 0;
                    }
                    &:hover{
                        &:before{
                            @include transaction( all, 0.4s, ease);
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    // version_2
    &.version_2 {
        grid-template-columns: 1fr 1fr;
        @media (max-width: 900px){
            grid-template-columns: 1fr;
        }
        .item:first-child{
            display: block;
            margin-bottom: 0;
            grid-row-start: 1;
            grid-row-end: 3;
            grid-template-columns: 1fr;
            border: 4px solid var(--primary); 
            @media (max-width: 900px){
                grid-row-start: unset;
                grid-row-end: unset;
            }
            .media{
                overflow: hidden;
                height: 100%;
                a{
                    &:after{
                        background-image: -moz-linear-gradient( 90deg, rgb(16,28,48) 0%, rgba(16,28,48,0) 100%, rgb(16,28,48) 100%);
                        background-image: -webkit-linear-gradient( 90deg, rgb(16,28,48) 0%, rgba(16,28,48,0) 100%, rgb(16,28,48) 100%);
                        background-image: -ms-linear-gradient( 90deg, rgb(16,28,48) 0%, rgba(16,28,48,0) 100%, rgb(16,28,48) 100%);
                        display: block;
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    img{
                        height: 100%;
                    }
                }
                .box-img{
                    height: 100%;
                }
            }
            .content{
                width: 100%;
                background-color: transparent;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 60px;
                @media (max-width: 1024px){
                    padding: 40px;
                }
                @media (max-width: 900px){
                    padding: 30px;
                }                

                @media (max-width: 350px){
                    padding: 10px;
                }
                .post-meta{
                    width: max-content;
                    .item-meta{
                        &.wp-author{
                            margin-left: 0px;
                        }
                        .left{
                            color: #ffffff;
                        }
                        .right{
                            a,.by{
                                color: #ffffff;
                            }
                            a{
                                @include transaction( all, 0.2s, ease);
                                &:hover{
                                    @include transaction( all, 0.2s, ease);
                                    color: var(--primary);
                                }
                            }
                        }
                    }   
                }
                .post-title{
                    a{
                        font-size: 24px;
                        font-weight: 800;
                        @include transaction( all, 0.2s, ease);
                        color: #ffffff;
                        &:hover{
                            @include transaction( all, 0.2s, ease);
                            color: var(--primary);
                        }
                    }
                }
            }
        }
        .item:not(:first-child){
            display: flex; 
            @media (max-width: 900px){
                flex-flow: column;
            }  
            .media{
                width: 40%;
                flex: 0 0 40%;
                overflow: hidden;

                @media (max-width: 1024px){
                    width: 50%;
                    flex: 0 0 50%;
                } 
                @media (max-width: 900px){
                    width: 100%;
                    flex: 0 0 auto;
                    img{
                        width: 100%;
                    }
                }
            }
            .content{
                width: 60%;
                flex: 0 0 60%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                padding: 30px 40px;
                @media (max-width: 1024px){
                    width: 50%;
                    flex: 0 0 50%;
                    padding: 15px 20px;
                }
                @media (max-width: 900px){
                    width: 100%;
                    flex: 0 0 auto;
                    padding: 40px 20px;
                }
                .post-meta{
                    width: max-content;
                    @media (max-width: 1024px){
                        flex-flow: column;
                        li:nth-child(2){
                            display: none;
                        }
                    }

                    @media (max-width: 900px){
                        flex-flow: row;
                        li:nth-child(2){
                            display: block;
                        }
                    }

                    .item-meta{
                        @media (max-width: 1024px){
                            justify-content: start;
                            align-items: flex-start;
                            margin: 0;
                            margin-bottom: 5px;
                        }
                        &.wp-author{
                            margin-left: 0px;
                            @media( max-width: 900px ){
                                margin-right: 5px;
                            }
                        }
                        &.post-comment{
                            @media( max-width: 900px ){
                                margin-left: 5px;
                            }
                        }
                    }   
                }
            }
        }
        .item{
            position: relative;
            .media{
                a{
                    height: 100%;
                }
            }
            .content{
                padding: 0;
                margin: 0;
                text-align: left;
                .post-title{
                    padding: 0;
                    margin: 0;
                } 
            }
        }  
    }

    // version 3
    &.version_3{
        @media (max-width: 1024px) {
               row-gap: 50px;
        }
        .item{
            position: relative;
            .media{
                position: relative;
                overflow: hidden;
                a{
                    height: 100%;
                    display: initial;
                    &:after{
                        background-image: -moz-linear-gradient( 90deg, rgb(16,28,48) 0%, rgba(16,28,48,0) 100%, rgb(16,28,48) 100%);
                        background-image: -webkit-linear-gradient( 90deg, rgb(16,28,48) 0%, rgba(16,28,48,0) 100%, rgb(16,28,48) 100%);
                        background-image: -ms-linear-gradient( 90deg, rgb(16,28,48) 0%, rgba(16,28,48,0) 100%, rgb(16,28,48) 100%);
                        display: block;
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                img{
                    object-fit: cover;
                    height: 436px;
                }
            }
            .content{
                position: absolute;
                bottom: -20px;
                left: 20px;
                padding: 30px;
                padding-top: 0;
                margin: 0;
                background-color: transparent;
                box-shadow: unset;
                text-align: left;
                box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
                width: calc(100% - 40px);
                background: #fff;
                .post-title{
                    margin: 0;
                    font-size: 24px;
                    font-weight: 800;
                    margin-top: 6px;
                    bottom: 0;
                    a{
                        color: var(--heading);
                        @include transaction( all, 0.2s, ease);
                        &:hover{
                            @include transaction( all, 0.2s, ease);
                            color: var(--primary);
                        }
                    }
                }   

                .post-meta{
                    margin: 0;
                    display: flex;
                    flex-direction: column-reverse;

                    .item-meta{
                        width: 100%;
                        margin: 0;
                        justify-content: left;
                    }
                    .post-date{

                        padding: 0;
                        background-color: transparent;
                        display: inline-table;
                        margin-bottom: 31px;
                        .date-j{
                            background-color: var(--primary);
                            padding: 10px 10px;
                        }

                        .date-f{
                            background-color: var(--heading);
                            padding: 10px 12px;
                        }

                        span{
                            text-transform: uppercase;

                        }
                    }
                }

            }
        }
    } 

    // version 4
    &.version_4 {
        row-gap: 55px;
        .item{
            
            &:hover{
                .content{
                    &:before{
                        width: 100%;
                        @include transaction( all, 0.5s, ease);
                    }

                    .icon{
                        @include transaction( all, 0.5s, ease);
                        background-color: var(--primary);
                        
                    }
                }
            }

            .media{
                position: relative;
                overflow: hidden;
                a{
                    img{
                        height: 280px;
                    }
                }
            }

            .content{
                text-align: left;
                padding: 30px 40px;
                padding-bottom: 24px;
                &:after,
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background-color: var(--heading);
                    @include transaction( all, 0.5s, ease);
                }

                &:before{
                    background-color: var(--primary);
                    z-index: 2;
                    width: 0;
                }

                @media (max-width: 767px) {
                    padding: 20px;
                    padding-bottom: 27px;
                }
                .post-meta{
                    .post-date{
                        margin-right: 10px;
                        span{
                            text-transform: uppercase;
                            font-size: 11px;
                            font-weight: bold;
                        }
                    }
                    .item-meta{
                        .right{
                            a{
                                font-size: 13px;
                                font-weight: 500;
                            }
                        }
                        .left{
                            i{
                                font-size: 13px;
                            }
                        }
                    }
                }
                .post-title{
                    margin-bottom: 0;
                }
                .short_desc{
                    margin-bottom: 20px;
                    margin-top: 15px;
                    p{
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    @media (max-width: 400px) {
                        padding: 0;
                    }
                }

                .icon{
                    display: inline-flex;
                    width: 52px;
                    height: 52px;
                    border-radius: 50%;
                    background-color: var(--heading);
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    position: absolute;
                    z-index: 2;
                    right: 40px;
                    overflow: hidden;
                    @include transaction( all, 0.5s, ease);
                    i{
                        display: flex;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }

}