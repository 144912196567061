.wp-caption {
	margin-bottom: 1.5em;
	text-align: center;
	padding-top: 5px;
	max-width: 100%;

	img {
		border: 0 none;
		padding: 0;
		margin: 0;
	}
	img[class*="wp-image-"] { 
		display: block;
		margin: 0;
	}

	.wp-caption-text {
		text-align: left;
	    font-style: italic;
	    border-bottom: 2px solid #e6e6e6;
	    padding: 0.5407911001em 0;
	    font-size: 0.875em;
	    color: var(--text);
	    opacity: 0.8;
	}
}

