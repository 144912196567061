/*List Comment*/
#comments{


	/*Title Comment*/
	.title-comments{
		text-transform: uppercase;
		margin-bottom: 30px;
		font-size: 1.4em;
	}

	/*Fist Level*/
	.comment-lists{
		list-style-type: none;
		padding-left: 0;
		margin-left: 0;

		/*Children level*/
		.children{
			list-style-type: none;
			padding-left: 0;
			margin-left: 70px;

			@media(max-width: 767px){
				margin-left: 15px;
			}
		}


		/*Item*/
		.trackback,
		.pingback,
		.comment{
			
			.comment-body{

				padding-left: 140px;
				min-height: 100px;
				margin-bottom: 40px;

				@media(max-width: 767px){
					padding-left: 0;
				}

				.comment-meta{
					position: relative;
					margin-bottom: 10px;
						
						.avatar{
							position: absolute;
							left: -140px;
							top: 5px;
							border-radius: 50%;
							width: 100px;
							height: auto;
							@media(max-width: 767px){
								display: none;
							}
						}
						cite{
							font-weight: 600;
							color: #333;
							font-style: normal;
							font-size: 1.1em;
							a{
								font-weight: 600;
								color: #333;	
								font-style: normal;
								font-size: 1.1em;
							}
							
						}
					
					
					.comment-date{
						time{
							font-size: 0.9em;
						    color: #999;
						    font-weight: 500;
						    line-height: 1.4;
						    font-style: italic;
						}
					}
				
				}

				.comment-content{
					padding-bottom: 40px;
				    border-bottom: 1px solid;
				    border-color: #eee;

				    .comment-text{
				    	p{
				    		margin: 0 0 10px 0;
				    		padding: 0;
				    	}
				    	
				    }

				    .reply{
				    	a{
				    		font-weight: 500;
				    		color: var(--primary);

				    		&.comment-reply-link{
				    			margin-right: 10px;
							    font-weight: bold;
							    color: var(--primary);
							    position: relative;
							    padding-left: 18px;
							    text-transform: uppercase;
							    font-size: 12px;
				    			&:before{
				    				position: absolute;
				    				left: 0;
				    				top: 50%;
				    				margin-top: -7px;
				    				content: "\f10f";
				    				font-weight: 600;
	                                font-family: "ovaicon";
	                                -webkit-font-smoothing: antialiased;
	                                -moz-osx-font-smoothing: grayscale;
	                                display: inline-block;
	                                font-style: normal;
	                                font-variant: normal;
	                                line-height: 1;
	                                vertical-align: middle;
				    			}
				    			&:hover{
				    				color: #333;
				    			}
				    		}
				    		&.comment-edit-link{
				    			margin-right: 10px;
							    font-weight: bold;
							    color: var(--primary);
							    position: relative;
							    padding-left: 18px;
							    text-transform: uppercase;
							    font-size: 12px;
				    			&:before{
				    				position: absolute;
				    				left: 0;
				    				top: 50%;
				    				margin-top: -7px;
				    				content: "\f167";
				    				font-weight: 600;
	                                font-family: "ovaicon";
	                                -webkit-font-smoothing: antialiased;
	                                -moz-osx-font-smoothing: grayscale;
	                                display: inline-block;
	                                font-style: normal;
	                                font-variant: normal;
	                                line-height: 1;
	                                vertical-align: middle;
				    			}
				    			
				    		}
				    	}
				    	
				    }
				}

				#respond{
					margin-top: 0;
					border: 1px solid #eee;
					border-top: none;
					border-radius: 0;
					padding: 22px 25px 25px 25px;
				}


				
			}

			
		}

		.trackback, .pingback {
            .comment-body {
                padding-left: 0;
            }
        }
		
	}

}

/*Comment Form*/
#respond{
	clear: both;
	margin: 30px 0px;
	position: relative;

	.logged-in-as{
		padding-bottom: 15px;
	}


	#reply-title{
		margin-top: 0;
		margin-bottom: 5px;
		text-transform: uppercase;
		font-size: 1.4em;
		#cancel-comment-reply-link{
			float: right;
			text-align: right;
			position: relative;
			color: red;
		}
	}
	



	#commentform{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		clear: both;
		p{
			padding: 0px 15px;
			margin: 0;
			&.logged-in-as{
				padding-bottom: 15px;
			}
		}

		label{
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
			font-size: 0.9em;
			display: inherit;
		}
		

		.comment-notes{
			flex: 0 0 100%;
	    	max-width: 100%;
	    	margin-bottom: 30px;
		}

		.comment-form-author,
		.comment-form-email,
		.comment-form-url{
			flex: 0 0 100%;
	    	max-width: 100%;
	    	order: 1;
	    	margin-bottom: 30px;
	    	

	    	@media( min-width: 1024px ){
	    		flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
	    	}
	    	input{
	    		width: 100%;
	    	}
	    	
		}
		.comment-form-comment{
			flex: 0 0 100%;
	    	max-width: 100%;
	    	order: 2;
	    	margin-bottom: 30px;
	    	textarea{
	    		width: 100%;
	    	}
		}
		
		.form-submit{
			flex: 0 0 100%;
			max-width: 100%;
			order: 3;
		}

		.comment-form-cookies-consent{
			display: flex;
			line-height: 1;
			margin-bottom: 15px;
			width: 100%;
			label{
				padding-left: 5px;
				text-transform: inherit;
				font-weight: 500;
			}
		}
		
	}
}
