#scrollUp{
	bottom: 20px;
	right: 20px;
	border-radius: 4px;
	background-color: var(--primary);
	color: #fff;
	text-align: center;

	&:hover{
		background-color: #333;	
		@include transaction ( all, 0.3s, linear );
	}

	i{
		color: #fff;
	    text-align: center;
	    display: block;
	    font-weight: bold;
	    position: relative;
	    padding: 10px 10px 5px 10px;
	    &:before{
            font-weight: bold!important;
        }
	}

}