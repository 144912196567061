.ova-counter {
	@include transaction(all, 0.5s, ease);
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	row-gap: 15px;
	background-color: var(--primary);
	padding: 35px 30px; 
	text-align: left;

	&:hover {
		.title, .suffix, .description, i {
			@include transaction(all, 0.3s, ease);
		}
	}

	.icon {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 0 20px 0 0;
		i{
			@include transaction(all, 0.3s, ease);
			position: relative;
			display: inline-flex;
			font-size: 62px;
			color: var(--heading);
			z-index: 2;
		}
		svg {
			width: 62px;
			height: 62px;
			path {
				fill: var(--heading);
			}
		}
		i.icon-settings {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%); 
			font-size: 152px !important;
			color: var(--heading) !important;
			text-shadow: 0 0 20px rgb(0 0 0 / 7%);
			z-index: 1;
		}

	}

	.odometer {
		color: #fff; 
		font-size: 50px; 
	    font-weight: 800;
	    line-height: 1.3;
	    vertical-align: middle;
		.odometer-formatting-mark {
			display: none;
		}
		.odometer-inside {
			line-height: 1;
		}
	}

	.suffix {
		@include transaction(all, 0.3s, ease);
		color: #fff;
		font-size: 50px; 
		font-weight: 800;
		vertical-align: middle;
	}

	.title {
		@include transaction(all, 0.3s, ease);
		margin: 0; 
		font-size: 18px; 
		font-weight: 500;
		line-height: 1.3; 
		color: #fff;  
	}
    
    .description {
		@include transaction(all, 0.3s, ease);
		margin: 0; 
		font-size: 14px; 
		font-weight: 500;
		line-height: 1.5; 
		color: var(--text);  
	}


	// triangle position1
	&.position1 {
        &:before {
	        position: absolute;
	        content: "";
			top: -40px;
			right: 0;
			width: 60px;
			height: 40px; 
			background-color: #eb7615;
			clip-path: polygon(0 0, 0% 100%, 100% 100%);
		}
	}

	// triangle position2
	&.position2 {
        &:before {
	        position: absolute;
	        content: "";
			right: 32px;
		    bottom: 0;
		    width: 0;
		    border-top: 10px solid transparent;
		    border-bottom: 10px solid transparent;
		    border-right: 15px solid #fff;
		}
	}

	// triangle position3
	&.position3 {
        &:before {
	        position: absolute;
	        content: "";
			left: 50px;
		    bottom: -46px;
		    width: 0;
		    border-left: 3px solid transparent;
		    border-top: 19px solid #fff;
		    border-bottom: 27px solid transparent;
		    border-right: 46px solid transparent;
		}
	}

	// triangle position4
	&.position4 {
        &:before {
	        position: absolute;
	        content: "";
			top: -30px;
			right: 0;
			width: 30px;
			height: 30px; 
			background-color: #e3e5e9;
			clip-path: polygon(100% 0, 100% 100%, 0 100%);
			@media (max-height: 767px) {
				content: none;
			}
		}
	}

	// triangle position5
	&.position5 {
        &:before {
	        position: absolute;
	        content: "";
			bottom: -30px;
			left: 0;
			width: 30px;
			height: 30px; 
			background-color: var(--primary);
			clip-path: polygon(0 100%, 100% 0, 0 0);
			@media (max-height: 767px) {
				content: none;
			}
		}
	}

	&.path1 {
        clip-path: polygon(0 0, 100% 0, 97% 100%, 0% 100%);
	}

	&.path2 {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%);
	}

	&.path3 {
		clip-path: polygon(0 0, 97% 0, 100% 100%, 0 100%);
	}

	&.path4 {
		clip-path: polygon(3% 0, 100% 0, 100% 100%, 0 100%);
	}

	//template1
    &.ova-counter-template1 {
    	flex-wrap: nowrap;
    	@media (max-width: 420px) {
    		padding: 30px 15px;
    	}
    	&:hover {
    		.icon {
    			i.icon-settings {
    				color: #fff !important; 
    			}
    			&:before {
    				@include transaction(all, 0.3s, ease);
    				background-color: #fff;
    			}
    		}
    	}
        .icon {
            margin: 0 60px 0 -60px;
            i {
            	color: var(--primary);
            }
            &:before {
            	@include transaction(all, 0.3s, ease);
            	position: absolute;
		        content: "";
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%); 
				width: 68px;
				height: 68px;
				border-radius: 50%;
				background-color: var(--heading);
				z-index: 2;
            }
        }
    }

	//template2 
    &.ova-counter-template2 { 
    	.icon {
    		i {
    			z-index: 1; 
    		}
    	}
        .odometer, .suffix {
            font-size: 70px;
        }
        .title {
            font-size: 20px;
        }
    }

	//template3 
    &.ova-counter-template3 { 
    	display: inline-flex;
    	justify-content: center;
    	text-align:center;
    	padding: 30px;
    	background-color: #fff;
    	box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.1);
        .odometer, .suffix {
        	color: var(--heading); 
        	font-size: 40px;
        }
        .title {
        	font-size: 14px;
        	text-transform: uppercase;
        	color: var(--text);  
        }
    }

    //template4
    &.ova-counter-template4 { 
    	background-color: #fff;
        .odometer, .suffix {
            font-size: 60px;
            color: var(--primary);
        }
        .title {
        	color: var(--text);
        	padding-left: 18px;
        }
        .odometer-wrapper {
        	display: flex;
        	align-items: center;
        	justify-content: center;
        }
    }

    //template5
    &.ova-counter-template5 { 
    	background-color: #fff;
    	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
        .odometer, .suffix {
            color: var(--heading);
        }
        .title {
        	font-size: 20px;
        	font-weight: bold;
        	color: var(--primary);
        	padding-bottom: 20px;
        }
    }

}

// custom class ova counter digit max width
.ova-counter-digit-max-width {
	.ova-counter {
		.odometer {
			.odometer-inside {
				.odometer-digit {
					max-width: 30px;
				}
			}	
		}
	}
}