/* mask image home 4 */
.ova-mask-image-revslider{
  	-webkit-mask-image: url('assets/img/mask-bg-image-revslider.png');
    mask-image: url('assets/img/mask-bg-image-revslider.png');
    -webkit-mask-size: cover;
  	mask-size: cover;
  	-webkit-mask-position: center;
  	mask-position: center;
  	-webkit-mask-repeat: no-repeat;
  	mask-repeat: no-repeat;
}

@media (max-width: 767px) {
	.ova-mask-image-revslider{
	    -webkit-mask-image: none !important; 
	}
}