.ova-mc4wp-form{
    position: relative;
    .email {
        display: flex;
        align-items: center;
        width: 100%;
        input{
            border-radius: unset;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.2;
            color: #959ca9;
            width: 100%; 
            padding: 24px 0 24px 30px;
            background-color: transparent;
            border: 1px solid #fff;
            &::placeholder {
                color: #959ca9;
                font-weight: 500;
                opacity: 1;
            }
            
            &:-webkit-autofill {
                transition-delay: 600s; 
            }
        }
    }

    .submit{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0px, -50%);
        background-color: var(--primary);

        button{
            padding: 15px;
            border-radius: unset;   
            i{
                font-size: 16px;
                color: #ffffff;
                display: flex;
                position: relative;
                z-index: 3;
            }
        }

        &:before{
            transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            -webkit-transform-origin: top;
            transform-origin: top;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -webkit-transform: scaleY(0);
            transform: scaleY(0);

            background-color: var(--heading);
        }

        &:hover{
            &:before{
                transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
            }
        }
    }
}
.mc4wp-response{
    p{
        color: #fff;
        margin: 15px 0px;
    }
}