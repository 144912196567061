// social icon
.elementor-grid-item {
	.elementor-social-icon {
		position: relative;
		overflow: hidden;
		&:hover {
			&:before {
               @include transaction( all, 0.5s, ease);
				-webkit-transform: scaleY(1);
			    transform: scaleY(1);
			}
		}
		&:before {
	    	@include transaction( all, 0.5s, ease);
	    	position: absolute;
		    content: "";
		    top: 0;
		    left: 0;
		    right: 0;
		    height: 100%;
		    background-color: var(--primary);
		    -webkit-transform-origin: top;
		    transform-origin: top;
		    -webkit-transform-style: preserve-3d;
		    transform-style: preserve-3d;
		    -webkit-transform: scaleY(0);
		    transform: scaleY(0);
		    z-index: -1; 
		}
	}
}

//button
.elementor-button-wrapper {
	.elementor-button {
		position: relative;
		overflow: hidden;
		background-color: var(--primary);

		&:hover {
			&:before {
               @include transaction( all, 0.5s, ease);
				-webkit-transform: scaleY(1);
			    transform: scaleY(1);
			}
		}

		&:before {
	    	@include transaction( all, 0.5s, ease);
	    	position: absolute;
		    content: "";
		    top: 0;
		    left: 0;
		    right: 0;
		    height: 100%;
		    width: 101%;
		    background-color: transparent;
		    -webkit-transform-origin: top;
		    transform-origin: top;
		    -webkit-transform-style: preserve-3d;
		    transform-style: preserve-3d;
		    -webkit-transform: scaleY(0);
		    transform: scaleY(0); 
		}
		.elementor-button-text {
			z-index: 1;
			letter-spacing: 1px;
		}
	}
}

// stacked icon box animation
.ova-icon-box-icon-animation {

	.elementor-icon {
		position: relative;
		i {
			z-index: 1;
		}
		&:before {
			position: absolute;
			content: "";
			top: 50%;
			left: 50%;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			width: 110px;
			height: 110px;
			background-color: #fc8e32;
			-webkit-animation: lineScale 2.5s linear infinite;
    		-moz-animation: lineScale 2.5s linear infinite;
			animation: lineScale 2.5s linear infinite;
		}
		&:after {
			position: absolute;
			content: "";
			top: 50%;
			left: 50%;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			width: 86px;
			height: 86px;
			background-color: var(--heading);
			-webkit-animation: lineScale 2.5s linear infinite;
    		-moz-animation: lineScale 2.5s linear infinite;
			animation: lineScale 2.5s linear infinite;
		}
	}

	@keyframes lineScale {
		50% { 
	        -webkit-transform: translate(-50%, -50%) scale(1.5);
	        -moz-animation: translate(-50%, -50%)scale(1.5);
	        transform: translate(-50%, -50%) scale(1.5);
	    }
	    50% { 
	        -webkit-transform: translate(-50%, -50%) scale(0.8);
	        -moz-animation: translate(-50%, -50%)scale(0.8);
	        transform: translate(-50%, -50%) scale(0.8);
	    }
	}

} 